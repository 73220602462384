import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Loader from "../../../Components/Loader/Loader";
import { getBSMasterList } from "../../../Redux/API/api_master";
import { getPOMasterList } from "../../../Redux/API/api_payment";

export default function POMasterList() {
  const [masterData, setMasterData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);

  const token = useSelector((state) => state.saveTokenReducer.token);

  function GetEditButton(rowData) {
    return (
      <Tooltip title="" placement="bottom">
        <span>
          <Button href="javascript:void(0)">
            <span>
              <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }

  function clickEditHardCodeMaster(rowData) {
    let id = rowData.poId;
    let idEncrypted = btoaSecure(id);
    window.location.href = "/addPO?id=" + idEncrypted;
  }

  const columns = [
    { title: "PO id", field: "poId", hidden: true },
    { title: "PO Number", field: "poNumber" },
    {
      title: "PO Date",
      field: "poDate",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    { title: "Consultant Name", field: "consultantName" },
    { title: "Vendor Code", field: "vendorCode" },
    { title: "PO Value", field: "poValue" },
    { title: "PO creator", field: "poCreaterName" },
    {
      title: "Status",
      field: "statusValue",
    },
  ];

  function clickAddHardCodeMaster() {
    window.location.href = "/addPO";
  }

  function getPoList() {
    setIsLoader(true);
    getPOMasterList(token, 0)
      .then((response) => {
        setMasterData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getPoList();
  }, [token]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} className="d-flex jc-space-bt">
          <Grid item xs={9} className="text-left ml-1">
            <h2>PO Master List</h2>
          </Grid>
          <Grid item xs={3} className="text-right"></Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title=""
            columns={columns}
            data={masterData}
            icons={tableIconsInputScreen}
            options={{
              exportButton: {
                csv: true,
                pdf: false,
              },
              search: true,
              pageSize: 10,
              exportAllData: true,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
              exportFileName: "POMasterList",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
            actions={[
              {
                icon: AddIcon,
                isFreeAction: true,
                tooltip: "Add PO",
                onClick: () => {
                  clickAddHardCodeMaster();
                },
              },
              {
                icon: GetEditButton,
                tooltip: "Edit PO",
                onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={1} className="mb-1">
          <Link to={"/admin"}>
            <Button
              type="submit"
              className="pt-button--tertiary"
              id="SupplierLoginBtn"
              fullWidth>
              &lt; &lt; Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {/*            <Footer />*/}
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}

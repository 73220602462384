import { Switch, Route } from "react-router-dom";
import LoginRoot from "../Screens/LoginRegister/LoginRoot";
import NoAccess from "../Screens/UnAuthorizeAccess/UnauthorizedAccess";

export const LoginRoute = (
  <Switch>
    <Route exact path="/" render={() => <LoginRoot />} />
  </Switch>
);

export const NoAccessRoute = (
  <Switch>
    <Route exact path="/NoAccess" render={() => <NoAccess />} />
  </Switch>
);

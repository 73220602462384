import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  addStateForCompany,
  getCompanyGstList,
  getCompanyList,
  getCompanyListById,
  getCountryList,
  getStateList,
  getTypeOfRegList,
} from "../../../Redux/API/api_master";
import { addCompanyMaster } from "./../../../Redux/API/api_master";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddCompanyMaster() {
  const classes = useStyles();
  const [stateData, setStateData] = useState([]);
  const [pageHeader, setPageHeader] = useState("Add Company");
  const [data, setData] = useState([]);

  const [companyCode, setCompanyCode] = useState("");
  const [companyCodeHelper, setCompanyCodeHelper] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameHelper, setCompanyNameHelper] = useState("");
  const [areCode, setARECode] = useState("");
  const [areCodeHelper, setARECodeHelper] = useState("");
  const [iecCode, setIECCode] = useState("");
  const [iecCodeHelper, setIECCodeHelper] = useState("");
  const [country, setCountry] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [mergedEntity, setMergedEntity] = useState([]);
  const [mergedEntityValue, setMergedEntityValue] = useState("");
  let [isLoader, setIsLoader] = useState(false);
  const [status, setStatus] = useState(true);

  //* Dialog Pop-Up
  const [openForm, setOpenForm] = useState(false);
  const [state, setState] = useState([]);
  const [stateValue, setStateValue] = useState("");
  const [typeOfReg, setTypeOfReg] = useState([]);
  const [typeOfRegValue, setTypeOfRegValue] = useState("");
  const [statusValue, setStatusValue] = useState(true);
  const [regNo, setRegNo] = useState("");
  const [companyGSTId, setCompanyGSTId] = useState(0);

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/companyList";
    }
    // getStateDetails();
    // getCompanyDetails();
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleChangeCompanyCode = (e) => {
    setCompanyCode(e.target.value);
    if (e.target.value.length === 0) {
      setCompanyCodeHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setCompanyCodeHelper("Length cannot excced more than 200.");
    } else {
      setCompanyCodeHelper("");
    }
  };
  const handleChangeCompanyName = (e) => {
    setCompanyName(e.target.value);
    if (e.target.value.length === 0) {
      setCompanyNameHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setCompanyNameHelper("Length cannot excced more than 200.");
    } else {
      setCompanyNameHelper("");
    }
  };
  const handleChangeARECode = (e) => {
    setARECode(e.target.value);
    if (e.target.value.length === 0) {
      setARECodeHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setARECodeHelper("Length cannot excced more than 200.");
    } else {
      setARECodeHelper("");
    }
  };
  const handleChangeIECCode = (e) => {
    setIECCode(e.target.value);
    if (e.target.value.length === 0) {
      setIECCodeHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setIECCodeHelper("Length cannot excced more than 200.");
    } else {
      setIECCodeHelper("");
    }
  };

  const handleChangeCountry = (e) => {
    setCountryValue(e.target.value);
  };
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleChangeMergedEntity = (e) => {
    setMergedEntityValue(e.target.value);
    if (e.target.value > 0) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };
  //! Dialog Pop-Up functions
  const handleCloseForm = () => {
    setOpenForm(false);
  };
  const handleChangeState = (e) => {
    setStateValue(e.target.value);
  };
  const handleChangeTypeOfReg = (e) => {
    setTypeOfRegValue(e.target.value);
  };
  const handleChangeRegNo = (e) => {
    setRegNo(e.target.value);
  };
  const handleChangeGstStatus = (e) => {
    setStatusValue(e.target.value);
  };

  //! Dropdown Get Methods
  function getCountry() {
    getCountryList(siteToken)
      .then((response) => {
        setCountry(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getParentCompany() {
    getCompanyList(siteToken)
      .then((response) => {
        setMergedEntity(response.filter((c) => c.status === true));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getState(id) {
    getStateList(siteToken, id)
      .then((response) => {
        setState(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getTypeOfReg() {
    getTypeOfRegList(siteToken)
      .then((response) => {
        setTypeOfReg(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //! Material Table
  const columns = [
    { title: "companyGSTId", field: "companyGSTId", hidden: true },
    { title: "RegId", field: "typeOfReg", hidden: true },
    { title: "stateId", field: "stateID", hidden: true },
    { title: "Type Of Reg.", field: "regName" },
    { title: "State", field: "stateName" },
    { title: "Registration No.", field: "registrationNumber" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (rowData.status === true ? "Active" : "Inactive"),
    },
  ];
  function GetEditButton(rowData) {
    return (
      <Tooltip title="Edit HardCode Master" placement="bottom">
        <span>
          <Button href="javascript:void(0)">
            <span>
              <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function fillGridData(rowData) {
    setCompanyGSTId(rowData.companyGSTId);
    setOpenForm(true);
    setStateValue(rowData.stateID);
    setTypeOfRegValue(rowData.typeOfReg);
    setRegNo(rowData.registrationNumber);
    setStatusValue(rowData.status);
  }

  function resetGridData() {
    setOpenForm(true);
    setCompanyGSTId(0);
    setStateValue("");
    setTypeOfRegValue("");
    setRegNo("");
    setStatusValue(true);
  }

  //! Get Company
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");
  function getCompanyDetails() {
    getCompanyListById(siteToken, atobSecure(encryptedId))
      .then((response) => {
        setCompanyCode(response[0].companyCode);
        setCompanyName(response[0].companyName);
        setARECode(response[0].areCode);
        setIECCode(response[0].iecCode);
        setCountryValue(response[0].country);
        setMergedEntityValue(response[0].parentCompany);
        setStatus(response[0].status);
        getState(response[0].country);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getStateDetails() {
    getCompanyGstList(siteToken, atobSecure(encryptedId))
      .then((response) => {
        setStateData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //! Post Method
  var formdata = {
    companyCode: companyCode,
    companyName: companyName,
    areCode: areCode,
    iecCode: iecCode,
    country: countryValue,
    parentCompany: mergedEntityValue === "" ? null : mergedEntityValue,
    status: status,
    createdBy: userDetails.userId,
  };
  var formdataEdit = {
    companyId: atobSecure(encryptedId),
    companyCode: companyCode,
    companyName: companyName,
    areCode: areCode,
    iecCode: iecCode,
    country: countryValue,
    parentCompany: mergedEntityValue === "" ? null : mergedEntityValue,
    status: status,
    createdBy: userDetails.userId,
  };

  var gridFormdata = {
    companyId: parseInt(atobSecure(encryptedId)),
    typeOfReg: typeOfRegValue,
    stateID: stateValue,
    registrationNumber: regNo,
    status: statusValue,
    createdBy: userDetails.userId,
  };
  var gridFormdataEdit = {
    companyGSTId: companyGSTId,
    companyId: parseInt(atobSecure(encryptedId)),
    typeOfReg: typeOfRegValue,
    stateID: stateValue,
    registrationNumber: regNo,
    status: statusValue,
    createdBy: userDetails.userId,
  };

  function addCompanyMasterData() {
    setIsLoader(true);
    var payloadData;
    {
      encryptedId ? (payloadData = formdataEdit) : (payloadData = formdata);
    }
    addCompanyMaster(payloadData, siteToken)
      .then((response) => {
        if (response.status == 1 && !encryptedId) {
          handleSnackOpen("Company Added Successfully.", "success");
          setIsLoader(false);
          //reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Company Already Exists.", "warning");
          //reset();
        } else if (response.status == 1 && encryptedId) {
          getCompanyDetails();
          handleSnackOpen("Data Updated Successfully.", "success");
          setIsLoader(false);
          //reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Company.", "error");
          //reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  function addStateData() {
    setIsLoader(true);
    var payloadData;
    {
      companyGSTId > 0
        ? (payloadData = gridFormdataEdit)
        : (payloadData = gridFormdata);
    }
    addStateForCompany(payloadData, siteToken)
        .then((response) => {
        if (response.status == 1 && companyGSTId === 0) {
          handleSnackOpen("Company GST Added Successfully.", "success");
          setOpenForm(false);
          setIsLoader(false);
          getStateDetails();
          //reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Company GST Already Exists.", "warning");
          //reset();
        }
        else if (response.status == -1) {
            setIsLoader(false);
            handleSnackOpen("The Entered Registration Number is already exist.", "warning");
        }
        else if (response.status == 1 && companyGSTId > 0) {
          handleSnackOpen("Data Updated Successfully.", "success");
          setOpenForm(false);
          getStateDetails();
          setIsLoader(false);
          //reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Company GST.", "error");
          //reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  useEffect(() => {
    getCountry();
    getParentCompany();
    if (encryptedId) {
      getCompanyDetails();
      setPageHeader("Edit Company");
      getTypeOfReg();
      getStateDetails();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" className="upload-block pt-1">
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Company Code"
            id="companyCode"
            name="companyCode"
            className=""
            fullWidth
            variant="filled"
            value={companyCode}
            helperText={companyCodeHelper}
            inputProps={{ maxLength: 100 }}
            onChange={handleChangeCompanyCode}
          />
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            label="Company Name"
            id="companyName"
            name="companyName"
            className=""
            fullWidth
            value={companyName}
            helperText={companyNameHelper}
            onChange={handleChangeCompanyName}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            label="ARE Code"
            id="areCode"
            name="areCode"
            className=""
            fullWidth
            variant="filled"
            value={areCode}
            helperText={areCodeHelper}
            inputProps={{ maxLength: 100 }}
            onChange={handleChangeARECode}
          />
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            label="IEC Code"
            id="iecCode"
            name="iecCode"
            className=""
            fullWidth
            variant="filled"
            value={iecCode}
            helperText={iecCodeHelper}
            inputProps={{ maxLength: 100 }}
            onChange={handleChangeIECCode}
          />
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Country"
            id="country"
            name="Type"
            className=""
            fullWidth
            value={countryValue}
            onChange={handleChangeCountry}
            variant="filled">
            {country.map((item) => (
              <MenuItem key={item.countryId} value={item.countryId}>
                {item.countryName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Parent Company"
            id="country"
            name="Type"
            className=""
            fullWidth
            value={mergedEntityValue}
            onChange={handleChangeMergedEntity}
            variant="filled">
            <MenuItem value={0}>None</MenuItem>
            {mergedEntity.map((item) => (
              <MenuItem key={item.companyId} value={item.companyId}>
                {item.companyCode}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Status"
            id="status"
            name="Status"
            className=""
            fullWidth
            disabled={mergedEntityValue > 0 ? true : false}
            value={status}
            onChange={handleChangeStatus}
            variant="filled">
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>
        {encryptedId ? (
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={stateData}
              icons={tableIconsInputScreen}
              options={{
                // exportButton: {
                //   csv: false,
                //   pdf: false,
                // },
                search: true,
                emptyRowsWhenPaging: false,
              }}
              actions={[
                {
                  icon: AddIcon,
                  isFreeAction: true,
                  tooltip: "Add State and GSTIN",
                  onClick: () => {
                    resetGridData();
                  },
                },
                {
                  icon: GetEditButton,
                  tisFreeAction: true,
                  tooltip: "Edit State and GSTIN",
                  onClick: (event, rowData) => fillGridData(rowData),
                },
              ]}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        className="upload-block d-flex jc-center pb-2 mt-1">
        {encryptedId ? (
          <Grid item xs={12} md={1} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={addCompanyMasterData}
              disabled={!companyCode || !companyName || !areCode || !iecCode}
              fullWidth>
              Update
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12} md={1} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={addCompanyMasterData}
              disabled={
                !companyCode ||
                !companyName ||
                !areCode ||
                !iecCode ||
                !countryValue
              }
              fullWidth>
              Submit
            </Button>
          </Grid>
        )}

        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/companyList"}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseForm(event, reason);
          }
        }}
        aria-labelledby="litigation-form--registration"
        open={openForm}
        maxWidth={"lg"}
        fullWidth>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h4 className={classes.sectionHeader}>
              {companyGSTId > 0 ? "Edit Company GST" : "Add Company GST"}
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className="">
                <TextField
                  select
                  label="Type Of Reg."
                  id="state"
                  name="state"
                  className=""
                  fullWidth
                  value={typeOfRegValue}
                  onChange={handleChangeTypeOfReg}
                  variant="filled">
                  <MenuItem value={0}>
                    <em>Select</em>
                  </MenuItem>
                  {typeOfReg.map((item) => (
                    <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4} className="">
                <TextField
                  select
                  label="State"
                  id="state"
                  name="state"
                  className=""
                  fullWidth
                  value={stateValue}
                  onChange={handleChangeState}
                  variant="filled">
                  <MenuItem value={0}>
                    <em>Select</em>
                  </MenuItem>
                  {state.map((item) => (
                    <MenuItem key={item.stateId} value={item.stateId}>
                      {item.state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4} className="">
                <TextField
                  label="Registration No."
                  id="branchCode"
                  name="branchCode"
                  className=""
                  fullWidth
                  value={regNo}
                  onChange={handleChangeRegNo}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={4} className="">
                <TextField
                  select
                  label="Status"
                  id="gststatus"
                  name="gstStatus"
                  className=""
                  fullWidth
                  value={statusValue}
                  onChange={handleChangeGstStatus}
                  variant="filled">
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            onClick={handleCloseForm}>
            Close
          </Button>
          {companyGSTId > 0 ? (
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              disabled={!typeOfRegValue || !stateValue || !regNo}
              onClick={addStateData}>
              Update
            </Button>
          ) : (
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              disabled={
                !typeOfRegValue || !stateValue || !regNo || !statusValue
              }
              onClick={addStateData}>
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}

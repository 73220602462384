import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { getRefundSummaryReportFile } from "../../Redux/API/api_reports";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getCompany } from "../../Redux/API/api_createEnquiry";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { getAllLocationList } from "../../Redux/API/api_toolCaseList";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `0`,
    marginBottom: `0`,
    //borderBottom: `2px solid #00BCBC`,
  },
  m1: {
    marginBottom: `1rem`,
    marginTop: `1rem`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    margin: `1rem 0 1rem 0`,
    paddingBottom: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
    padding: `1rem`,
  },
}));
const months = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

export default function RefundSummaryReport() {
  const classes = useStyles();
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const [cfrData, setCFRData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);
  const [company, setCompany] = useState([]);
  const [companyValue, setCompanyValue] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [cutOffMonth, setCutOffMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [location, setLocation] = useState([]);
  const [locationValue, setLocationValue] = useState(0);

  //* Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpenSnackbar(true);
  };
  const handleClose = (e) => {
    setOpenSnackbar(false);
  };

  const handleCompany = (e) => {
    setCompanyValue(e.target.value);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date.toISOString());
    setMinDate(date.toISOString());
  };
  const handleToDateChange = (date) => {
    setToDate(date.toISOString());
  };
  const handleCutOffMonth = (e) => {
    setCutOffMonth(e.target.value);
  };
  const handleYearChange = (date) => {
    setSelectedYear(date);
  };
  const handleLocationChange = (e) => {
    setLocationValue(e.target.value);
  };

  function getCompanyList() {
    getCompany(siteToken)
      .then((response) => {
        setCompany(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getAllLocation() {
    getAllLocationList(siteToken)
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function resetCFRReport() {
    setFromDate(null);
    setMinDate(null);
    setToDate(null);
    setCompanyValue(0);
    setCutOffMonth("");
    setSelectedYear(new Date());
    setLocationValue(0);
  }

  function generateReport() {
    setIsLoader(true);
    getRefundSummaryReportFile(
      siteToken,
      companyValue,
      cutOffMonth,
      selectedYear.toISOString(),
      fromDate,
      toDate,
      locationValue
    )
      .then((response) => {
        if (response.status === true) {
          window.location.href = response.result;
          handleSnackOpen("Issue report downloaded successfully.", "success");
        } else {
          handleSnackOpen(response.result, "error");
        }
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }

  useEffect(() => {
    getCompanyList();
    getAllLocation();
  }, []);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex fd-column">
          <h3 className={classes.sectionHeader}>Refund Summary Report</h3>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.m1}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            select
            label="Company"
            id=""
            name=""
            className=""
            fullWidth
            value={companyValue}
            onChange={handleCompany}
            variant="filled">
            <MenuItem key={0} value={0}>
              All
            </MenuItem>
            {company.map((item) => (
              <MenuItem key={item.companyId} value={item.companyId}>
                {item.companyName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            select
            label="Location"
            id=""
            name=""
            className=""
            fullWidth
            value={locationValue}
            onChange={handleLocationChange}
            variant="filled">
            <MenuItem key={0} value={0}>
              All
            </MenuItem>
            {location.map((item) => (
              <MenuItem key={item.stateId} value={item.stateId}>
                {item.state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="Cut-Off Year"
                          views={["year"]}
                         
              animateYearScrolling
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            select
            label="Cut-Off Month"
            id=""
            name=""
            className=""
                      fullWidth
                     
            value={cutOffMonth}
            onChange={handleCutOffMonth}
            variant="filled">
            {months.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="From Period Involved"
              format="dd/MM/yyyy"
                          fullWidth
                         
              value={fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="To Period Involved"
                          format="dd/MM/yyyy"
                          
              fullWidth
              value={toDate}
              onChange={handleToDateChange}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="d-flex jc-center">
        <Grid item xs={12} md={1} className="align-center width-100">
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            fullWidth
            disabled={!cutOffMonth || !selectedYear || !fromDate || !toDate}
            onClick={generateReport}>
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={1} className="align-center width-100">
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            fullWidth
            onClick={resetCFRReport}>
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/reports"}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      <>
        <SnackbarCustom
          open={openSnackbar}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}

import { baseApiURL } from "../../Utilities/Utility";

//? Get Apis - Dropdown
export async function getRatioTypeList(siteToken, id) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    try {
        const response = await fetch(
            baseApiURL + "/Master/GetRatioType?natureID=" + id,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getNatureOfServiceList(siteToken, id) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    try {
        const response = await fetch(
            baseApiURL + "/Master/GetNatureOfService?moduleId=" + id,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getPOMasterList(siteToken, id) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    try {
        const response = await fetch(
            baseApiURL + "/Payment/GetPOMaster?poId=" + id,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getConsultantNameList(siteToken, id) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    try {
        const response = await fetch(
            baseApiURL + "/Payment/GetConsultant?ConsultantId=" + id,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getToolCaseNoList(siteToken) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    try {
        const response = await fetch(
            baseApiURL + "/Payment/GetToolCaseIdNoList",
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getPOBasedOnConsultantList(siteToken, id) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    try {
        const response = await fetch(
            baseApiURL + "/Payment/GetPOMasterList?consultantId=" + id,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getToolCaseBasedData(
  siteToken,
  toolcaseId,
  invoiceValue,
  paymentId,
  ratioType
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    debugger;
    const response = await fetch(
      baseApiURL +
        "/Payment/GetPaymentRatioDetail?toolCaseId=" +
        toolcaseId +
        "&consultantInvoiceValue=" +
        invoiceValue +
        "&paymentId=" +
        paymentId +
        "&ratioType=" +
        ratioType,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getPaymentList(siteToken, id) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    try {
        const response = await fetch(
            baseApiURL + "/Payment/GetPayment?paymentId=" + id,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//? Post Methods
export async function addPOMaster(formData, siteToken) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(baseApiURL + "/Payment/SavePOMaster", options);
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function uploadPaymentDetails(formData, siteToken) {
    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formData,
    };
    try {
        const response = await fetch(baseApiURL + "/Payment/SavePayment", options);
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error : ", error);
    }
}

//? Edit Methods
export async function editPOMaster(formData, siteToken) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(baseApiURL + "/Payment/EditPOMaster", options);
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error : ", error);
    }
}

export async function UpdatePaymentDetails(formData, siteToken) {
    const options = {
        method: "put",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formData,
    };
    try {
        const response = await fetch(baseApiURL + "/Payment/EditPayment", options);
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error : ", error);
    }
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";

export default function EventReport() {
  useEffect(() => {}, []);

  return (
    <Grid container className="homepage-container">
      <Grid item xs={12} sm={12} md={12} className="mt-1 d-flex jc-center">
        <iframe
          title="Event Report"
          className="i-frame"
          width="1300"
          height="520"
          src="https://app.powerbi.com/reportEmbed?reportId=f667a528-d99c-4ff0-a947-45510c634d06&autoAuth=true&ctid=38ae3bcd-9579-4fd4-adda-b42e1495d55a"
          frameborder="0"
          allowFullScreen="true"
        />
      </Grid>
    </Grid>
  );
}

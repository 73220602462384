import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { getIssueHistoryList } from "../../Redux/API/api_events";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
    //borderBottom: `2px solid #00BCBC`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
}));
export default function IssueWorkFlow() {
  const classes = useStyles();
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const [workFlowData, setWorkFlowData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);
  var encryptedId = getURLParameter("id");
  const toolCaseDetails = useSelector(
    (state) => state.saveToolCaseNo.toolCaseNo
  );
  const [draftNo, setDraftNo] = useState("");
  const [toolCaseNo, setToolCaseNo] = useState("");

  const columnsForm = [
    { title: "Issue", field: "issueName" },
    { title: "Brief Description", field: "issueDescription" },
    { title: "Remarks", field: "issueRemark" },
    { title: "SubModule", field: "subModuleName" },
    { title: "Tax", field: "totalTax" },
    { title: "Interest", field: "interest" },
    { title: "Penalty/Others", field: "penalty" },
    { title: "Pre-Deposit", field: "predeposit" },
    { title: "Provision", field: "provision" },
    {
      title: "Current Appeal Stage",
      field: "issueLevelDetails.appealStageName",
    },
    { title: "Status", field: "issueLevelDetails.issueLevelStatusName" },
    { title: "Remand Id", field: "issueLevelDetails.remandCounter" },
    //{ title: "Higer Remand Id", field: "issueLevelDetails.higherRemandCounter" },
    { title: "Action Date", field: "createdDateTime" },
  ];

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }
  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        // return sParameterName[1];
      }
      return value;
    }
  }

  function getTotalWorkflow(value) {
    setIsLoader(true);
    getIssueHistoryList(siteToken, value)
      .then((response) => {
        setWorkFlowData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getTotalWorkflow(atobSecure(encryptedId));
    setToolCaseNo(toolCaseDetails.approved);
    setDraftNo(toolCaseDetails.draft);
  }, [encryptedId]);

  return (
    <div className="">
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12}>
          <h4 className="m-0">
            Tool Case No:- {toolCaseNo ? toolCaseNo : draftNo}
          </h4>
        </Grid>
        <Grid item xs={12} md={4} lg={3} className="d-flex fd-column">
          <h4 className={classes.sectionHeader}>Issue Workflow History</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={12}>
          <MaterialTable
            title=""
            columns={columnsForm}
            data={workFlowData}
            icons={tableIconsInputScreen}
            options={{
              search: true,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20, 50],
              pageSize: 10,
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportAllData: true,
              exportFileName: "IssueWorkFlow",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className="upload-block d-flex jc-center pb-2 mt-1">
        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/ToolCaseRegistration?id=" + encryptedId}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}

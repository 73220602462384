import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//import { baseAddressURL } from "../../../Utilities/Utility";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getSubModuleList } from "../../Redux/API/api_admin";
import {
  GetSectionUnderDocumentIssued,
  InsertEventDetails,
  UpdateEvents,
  getAdjudicatingAuthorityList,
  getDependantSubModuleList,
  getDocTypeList,
  getDocumentModeList,
  getDocumentTemplateList,
  getEventActionByList,
  getEventActionList,
  getEventsList,
  getSectionTypeList,
} from "../../Redux/API/api_events";
import { GetGeneralEnqFormDetails } from "../../Redux/API/api_createEnquiry";
import { IDTModuleId } from "../../Utilities/Utility";
import { saveEventToolCaseIdAction } from "./../../Redux/Actions/actions";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {
  GetRefundEventGridList,
  GetRefundEventList,
  GetRefundSectionDetails,
  GetRefundSubModuleList,
  InsertRefundEventDetails,
} from "../../Redux/API/api_refund";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `0.4em`,
    marginBottom: `0.4em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    paddingBottom: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
  mandatoryNote: {
    color: `red !important`,
    fontSize: `14px`,
    fontStyle: `italic`,
    display: `flex`,
    justifyContent: `flex-end`,
    marginBottom: `0`,
  },
}));

export default function AddRefundEvents() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add Refund Events");
  //! min date
  const fromdate = useSelector((state) => state.saveDateValidatorReducer.value);
  const [minDate, setMinDate] = useState(new Date(fromdate));
  let [isLoader, setIsLoader] = useState(false);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const refundCaseId = useSelector(
    (state) => state.saveEventToolCaseIdReducer.value
  );
  const toolCaseDetails = useSelector(
    (state) => state.saveToolCaseNo.toolCaseNo
  );
  const [draftNo, setDraftNo] = useState("");
  const [toolCaseNo, setToolCaseNo] = useState("");
  const metaDataId = useSelector((state) => state.saveMetaDataIdReducer.value);
  const isRefundCaseClosed = useSelector(
    (state) => state.saveRefundCaseStatus.value
  );
  const [eventId, setEventId] = useState(0);
  //* Event Hooks
  const [eventModule, setEventModule] = useState([]);
  const [eventModuleValue, setEventModuleValue] = useState("");
  const [eventAction, setEventAction] = useState([]);
  const [eventActionValue, setEventActionValue] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventActionBy, setEventActionBy] = useState([]);
  const [eventActionByValue, setEventActionByValue] = useState("");
  const [eventActionDate, setEventActionDate] = useState(null);

  const [sectionType, setSectionType] = useState([]);
  const [sectionTypeValue, setSectionTypeValue] = useState("");
  const [isSectionPresent, setIsSectionPresent] = useState(false);
  const [communicationType, setCommunicationType] = useState([]);
  const [communicationTypeValue, setCommunicationTypeValue] = useState("");
  const [docAmount, setDocAmount] = useState("");
  const [docAmountHelper, setDocAmountHelper] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [docDateValue, setDocDateValue] = useState(null);
  const [docMode, setDocMode] = useState([]);
  const [docModeValue, setDocModeValue] = useState("");
  const [selectedDocFile, setSelectedDocFile] = useState(null);
  const [inputDocFile, setInputDocFile] = useState(Date.now);
  const [downloadPath, setDownloadPath] = useState("");
  const [eventDueDate, setEventDueDate] = useState(null);
  const [authority, setAuthority] = useState([]);
  const [authorityValue, setAuthorityValue] = useState("");
  const [remarks, setRemarks] = useState("");
  const [docAmountReq, setDocAMountReq] = useState(false);
  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (alertType === "success" && !encryptedId) {
      window.location.href = "/addRefund?id=" + btoaSecure(metaDataId);
    }
  };

  //! Change Handlers

  const handleEventModule = (e) => {
    setEventActionValue("");
    setCommunicationTypeValue("");
    setAuthorityValue("");
    setEventModuleValue(e.target.value);
    getEventActionType(e.target.value);
    getCommunicationTYpe(e.target.value);
    getAdjudicatingAuthority(IDTModuleId, e.target.value);
    getEventActionBy(e.target.value);
  };

  const handleEventAction = (e) => {
    setEventActionValue(e.target.value);
    var name = eventAction.filter((c) => c.id === e.target.value);
    if (
      name[0].name === "File RFD-01 Application" ||
      name[0].name === "Receive RFD-04 Provisional Refund Order" ||
      name[0].name === "Receive PMT-03 recredit" ||
      name[0].name === "Receive Order RFD-06" ||
      name[0].name === "Receive Payment"
    ) {
      setDocAmountHelper("Please Add Document Amount");
      setDocAMountReq(true);
    } else {
      setDocAmountHelper("");
      setDocAMountReq(false);
    }
  };
  const handleEventActionBy = (e) => {
    setEventActionByValue(e.target.value);
  };
  const handleEventActionDate = (date) => {
    setEventActionDate(date.toISOString());
  };
  const handleSectionType = (e) => {
    setSectionTypeValue(e.target.value);
  };

  const handleCommType = (e) => {
    setCommunicationTypeValue(e.target.value);
  };
  const handleDocAmount = (e) => {
    let valid;
    setDocAmount(e.target.value);
    valid = /^((\d+(\.\d*)?)|(\.\d+))$/.test(e.target.value);
    if (e.target.value === "" && docAmountReq === true) {
      setDocAmountHelper("Cannot be left blank");
    } else if (!valid) {
      setDocAmountHelper("Numbers only");
    } else if (parseInt(e.target.value) === 0 && docAmountReq === true) {
      setDocAmountHelper("Cannot be 0");
    } else {
      setDocAmountHelper("");
    }
  };
  const handleDocNumber = (e) => {
    setDocNumber(e.target.value);
  };
  const handleDocDate = (date) => {
    setDocDateValue(date.toISOString());
  };
  const handleDocMode = (e) => {
    setDocModeValue(e.target.value);
  };
  const handleChangeDocFile = (event) => {
    setSelectedDocFile(event.target.files[0]);
  };
  const handleEventDueDate = (date) => {
    setEventDueDate(date.toISOString());
  };
  const handleRemarks = (e) => {
    setRemarks(e.target.value);
  };
  const handleAuthority = (e) => {
    setAuthorityValue(e.target.value);
  };
  //* DropDown Functions

  function getEventModule() {
    GetRefundSubModuleList(siteToken)
      .then((response) => {
        setEventModule(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getEventActionType(subModuleId) {
    GetRefundEventList(siteToken, subModuleId)
      .then((response) => {
        setEventAction(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getEventActionBy(submoduleId) {
    getEventActionByList(siteToken, submoduleId)
      .then((response) => {
        setEventActionBy(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getSectionList() {
    getSectionTypeList(siteToken)
      .then((response) => {
        setSectionType(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getCommunicationTYpe(subModuleId) {
    getDocumentTemplateList(siteToken, subModuleId)
      .then((response) => {
        setCommunicationType(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getDocumentMode() {
    getDocumentModeList(siteToken)
      .then((response) => {
        setDocMode(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getAdjudicatingAuthority(moduleId, subModuleId) {
    getAdjudicatingAuthorityList(siteToken, moduleId, subModuleId)
      .then((response) => {
        setAuthority(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getRefundEvents(id, eventId) {
    GetRefundEventGridList(siteToken, id, eventId)
      .then((response) => {
        setEventModuleValue(
          response[0].subModuleId === null ? "" : response[0].subModuleId
        );
        setEventActionValue(
          response[0].eventType === null ? "" : response[0].eventOrActionType
        );
        getAdjudicatingAuthority(IDTModuleId, response[0].subModuleId);
        getEventActionType(response[0].subModuleId);
        getCommunicationTYpe(response[0].subModuleId);
        getAdjudicatingAuthority(IDTModuleId, response[0].subModuleId);
        getEventActionBy(response[0].subModuleId);
        setEventActionByValue(response[0].eventOrActionBy);
        setEventActionDate(response[0].eventOrActionDate);
        setSectionTypeValue(response[0].sectionUnderWhichDocumentIssued);
        setCommunicationTypeValue(response[0].communicationType);
        setDocAmount(response[0].documentAmount);
        setDocNumber(response[0].documentNo);
        setDocDateValue(response[0].documentDate);
        setDocModeValue(response[0].documentMode);
        setRemarks(response[0].eventRemarks);
        setDownloadPath(response[0].documentPath);
        setEventDueDate(response[0].actionOrEventDueDate);
        setAuthorityValue(response[0].adjudicatingAuthority);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getRefundSection(id) {
    GetRefundSectionDetails(siteToken, id)
      .then((response) => {
        if (response.id !== 0) {
          setSectionTypeValue(response.id);
          setIsSectionPresent(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //! Encode Decode
  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  var encryptedId = getURLParameter("id");
  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }

  //! Save Events
  function submitEventDetails() {
    setIsLoader(true);
    var formdataPart1 = new FormData();
    formdataPart1.append("eventId", eventId);
    formdataPart1.append("refundCaseId", refundCaseId);
    formdataPart1.append(
      "subModuleId",
      eventModuleValue ? eventModuleValue : ""
    );
    formdataPart1.append(
      "eventOrActionType",
      eventActionValue ? eventActionValue : ""
    );
    formdataPart1.append(
      "eventOrActionBy",
      eventActionByValue ? eventActionByValue : ""
    );
    formdataPart1.append(
      "eventOrActionDate",
      eventActionDate ? eventActionDate : ""
    );
    formdataPart1.append(
      "sectionUnderWhichDocumentIssued",
      sectionTypeValue ? sectionTypeValue : ""
    );
    formdataPart1.append(
      "communicationType",
      communicationTypeValue ? communicationTypeValue : ""
    );
    formdataPart1.append(
      "documentAmount",
      docAmount === "" ? 0 : parseInt(docAmount)
    );
    formdataPart1.append("documentNo", docNumber ? docNumber : "");
    formdataPart1.append("documentDate", docDateValue ? docDateValue : "");
    formdataPart1.append("documentMode", docModeValue ? docModeValue : "");
    formdataPart1.append("eventRemarks", remarks ? remarks : "");
    formdataPart1.append("excelFile", selectedDocFile);
    formdataPart1.append(
      "actionOrEventDueDate",
      eventDueDate ? eventDueDate : ""
    );
    formdataPart1.append(
      "adjudicatingAuthority",
      authorityValue ? authorityValue : ""
    );
    formdataPart1.append("createdBy", userDetails.userId);
    InsertRefundEventDetails(formdataPart1, siteToken)
      .then((response) => {
        if (response.status === true) {
          setIsLoader(false);
          handleSnackOpen(response.result, "success");
        } else {
          setIsLoader(false);
          handleSnackOpen(response.result, "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }

  useEffect(() => {
    getEventModule();
    getSectionList();
    getDocumentMode();
    setToolCaseNo(toolCaseDetails.approved);
    setDraftNo(toolCaseDetails.draft);
    getRefundSection(refundCaseId);
    if (refundCaseId !== 0 && encryptedId) {
      getRefundEvents(refundCaseId, atobSecure(encryptedId));

      setEventId(atobSecure(encryptedId));
    }
  }, [siteToken, encryptedId, refundCaseId !== 0]);

  return (
    <div className="upload-screen">
      <Grid container spacing={2} direction="row" className={classes.subGrid}>
        <Grid item container xs={12} className="">
          <Grid item xs={3} className="d-flex jc-flex-start">
            <h3 className={classes.sectionHeader}>{pageHeader}</h3>
          </Grid>
          <Grid item xs={12}>
            <h4 className="m-0">
              Refund Case No:- {toolCaseNo ? toolCaseNo : draftNo}
            </h4>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Event/Action Module"
            id="businessUnitCode"
            name="businessUnitCode"
            className=""
            fullWidth
            value={eventModuleValue}
            onChange={handleEventModule}
            select
            variant="filled">
            {eventModule.map((item) => (
              <MenuItem key={item.subModuleId} value={item.subModuleId}>
                {item.subModuleName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            label="Event/Action Type"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={eventActionValue}
            onChange={handleEventAction}
            variant="filled">
            {eventAction.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Event/Action By"
            id="masterKey"
            name="Master Key"
            className=""
            select
            required
            value={eventActionByValue}
            onChange={handleEventActionBy}
            fullWidth
            variant="filled">
            {eventActionBy.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="Event/Action Date"
              format="dd/MM/yyyy"
              fullWidth
              required
              value={eventActionDate}
              onChange={handleEventActionDate}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            label="Section Under Which Document Issued"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={sectionTypeValue}
            onChange={handleSectionType}
            disabled={isSectionPresent}
            variant="filled">
            {sectionType.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Communication Type"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={communicationTypeValue}
            onChange={handleCommType}
            variant="filled">
            {communicationType.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Document Amount"
            id=""
            name=""
            className=""
            fullWidth
            variant="filled"
            value={docAmount}
            helperText={docAmountHelper}
            error={docAmountHelper ? true : false}
            onChange={handleDocAmount}
          />
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Document No"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            value={docNumber}
            onChange={handleDocNumber}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={3} className="">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="Document Date"
              format="dd/MM/yyyy"
              fullWidth
              value={docDateValue}
              onChange={handleDocDate}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Document Mode"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={docModeValue}
            onChange={handleDocMode}
            variant="filled">
            {docMode.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Upload Document"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            type="file"
            key={inputDocFile}
            onChange={handleChangeDocFile}
            variant="filled"
            InputLabelProps={{ shrink: true }}
            helperText="All File Types Are Allowed"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    className="p-0"
                    target="_blank"
                    href={downloadPath}
                    disabled={downloadPath ? false : true}>
                    <CloudDownloadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} className="">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="Due Date of Event Action"
              format="dd/MM/yyyy"
              fullWidth
              value={eventDueDate}
              onChange={handleEventDueDate}
              //helperText={dueDateHelper}
              //error={dueDateHelper ? true : false}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Adjudicating Authority"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={authorityValue}
            onChange={handleAuthority}
            variant="filled">
            {authority.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Remarks"
            id=""
            name=""
            className=""
            fullWidth
            variant="filled"
            value={remarks}
            onChange={handleRemarks}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        className="upload-block d-flex jc-center pb-2 mt-1">
        <Grid item xs={12} md={1} className="align-center width-100">
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            disabled={
              isRefundCaseClosed === true ||
              !eventActionByValue ||
              !eventActionDate ||
              docAmountHelper
                ? true
                : false
            }
            onClick={submitEventDetails}
            fullWidth>
            {encryptedId ? "Update" : "Submit"}
          </Button>
        </Grid>

        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/addRefund?id=" + btoaSecure(metaDataId)}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
  TextField,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getCompanyListByUserId } from "../../Redux/API/api_reports";
import {
  IDTModuleId,
  baseAddressURL,
  tableIconsInputScreen,
} from "../../Utilities/Utility";

import { tableOptionsUser } from "../../Themes/LightTheme";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import HistoryIcon from "@material-ui/icons/History";
import {
  approveToolCaseData,
  getAllLocationList,
  getToolCaseList,
  rejectToolCaseData,
} from "../../Redux/API/api_toolCaseList";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { getAllSubModuleList } from "../../Redux/API/api_admin";
import {
  GetRefundSubModuleList,
  GetRefundToolCaseList,
  approveRefundCaseData,
  rejectRefundCaseData,
} from "../../Redux/API/api_refund";
import { useDispatch } from "react-redux";
import { storeIsRefundCase } from "../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
  aTag: {
    color: `#00BCBC`,
    fontStyle: `italic`,
    borderBottom: `1px solid #00BCBC`,
    fontWeight: `bold`,
    width: `fit-content`,
    padding: `0`,
    margin: `0`,
    alignSelf: `flex-end`,
  },
}));

export default function RefundList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [refundCaseData, setRefundCaseData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);
  const [typeValue, setTypeValue] = useState(0);
  const [toolcaseNo, setToolcaseNo] = useState("");
  const [companyValue, setCompanyValue] = useState(0);
  const [location, setLocation] = useState([]);
  const [locationValue, setLocationValue] = useState(0);
  const [currentStage, setCurrentStage] = useState([]);
  const [currentStageValue, setCurrentStageValue] = useState(0);
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const [statusValue, setStatusValue] = useState(5);
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
  };

  const handleCompanyChange = (e) => {
    setCompanyValue(e.target.value);
  };
  const handleLocationChange = (e) => {
    setLocationValue(e.target.value);
  };
  const handleCurrentStageChange = (e) => {
    setCurrentStageValue(e.target.value);
  };
  const handleStatus = (e) => {
    setStatusValue(e.target.value);
  };
  const handleTypeChange = (e) => {
    setTypeValue(e.target.value);
  };
  //! Approve Dialog
  const [openFormApprove, setOpenFormApprove] = useState(false);
  const handleClickOpenFormApprove = () => {
    setOpenFormApprove(true);
  };
  const handleCloseFormApprove = () => {
    setOpenFormApprove(false);
  };
  // reject Dialog
  const [rejectReason, setRejectReason] = useState("");
  const [toolcaseId, setToolcaseID] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const handleClickOpenForm = () => {
    setOpenForm(true);
  };
  const handleCloseForm = () => {
    setRejectReason("");
    setOpenForm(false);
  };
  const handleRejectReason = (event) => {
    setRejectReason(event.target.value);
  };
  const handleToolcaseNo = (event) => {
    setToolcaseNo(event.target.value);
  };

  const [companyData, setCompanyData] = useState([]);
  function getCompany() {
    getCompanyListByUserId(siteToken, userDetails.userId)
      .then((response) => {
        setCompanyData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }

  function viewToolCaseData(row) {
    let id = row.metaDataId;
    let idEncrypted = btoaSecure(id);
    window.location.href = baseAddressURL + "/addRefund?id=" + idEncrypted;
  }
  function viewWorkflow(row) {
    let id = row.refundCaseId;
    dispatch(storeIsRefundCase(true));
    let idEncrypted = btoaSecure(id);
    window.location.href =
      baseAddressURL + "/workflowHistory?id=" + idEncrypted;
  }
  function GetEditButton(rowData, userdata) {
    return (
      <Tooltip title="View ToolCase Data" placement="top">
        <span>
          <Button>
            <span>
              <VisibilityIcon fontSize="small" className="attachFileIcon" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function handleApproveToolCase() {
    setIsLoader(true);
    approveRefundCaseData(siteToken, toolcaseId, userDetails.userId)
      .then((response) => {
        if (response === 1) {
          handleSnackOpen("Refund Case Approved Successfully.", "success");
          setIsLoader(false);
          getRefundToolCase();
          handleCloseFormApprove();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Approving Refund Case.", "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  function ApproveDocumentButton(rowData) {
    function handleApprovePopUp() {
      setOpenFormApprove(true);
      setToolcaseID(rowData.refundCaseId);
    }
    function handleReject() {
      setToolcaseID(rowData.refundCaseId);
      setOpenForm(true);
    }
    return (
      <div>
        <IconButton
          className="activationButton"
          aria-label="Approve"
          onClick={handleApprovePopUp}
          style={{ left: `-12px` }}>
          <Tooltip title="Approve">
            <CheckIcon style={{ color: `green` }} />
          </Tooltip>
        </IconButton>
        <IconButton
          className="activationButton"
          aria-label="reject"
          onClick={handleReject}>
          <Tooltip title="Reject">
            <CloseIcon style={{ color: `red` }} />
          </Tooltip>
        </IconButton>
      </div>
    );
  }

  function handleRejectToolCase() {
    setIsLoader(true);
    rejectRefundCaseData(
      siteToken,
      toolcaseId,
      rejectReason,
      userDetails.userId
    )
      .then((response) => {
        if (response === 1) {
          handleSnackOpen("Refund Case Rejected Successfully.", "success");
          setIsLoader(false);
          handleCloseForm();
          //getToolCase();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Rejecting Refund Case.", "error");
          handleCloseForm();
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  //! Drop-Down function calls
  function getSubModule(id) {
    GetRefundSubModuleList(siteToken, id, true)
      .then((response) => {
        setCurrentStage(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getAllLocation() {
    getAllLocationList(siteToken)
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getRefundToolCase() {
    setIsLoader(true);
    GetRefundToolCaseList(siteToken, 0, "", 0, 0, 5, 0)
      .then((response) => {
        setRefundCaseData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getFilterToolCase() {
    setIsLoader(true);
    GetRefundToolCaseList(
      siteToken,
      typeValue ? typeValue : 0,
      toolcaseNo ? toolcaseNo : "",
      locationValue ? locationValue : 0,
      currentStageValue ? currentStageValue : 0,
      statusValue,
      companyValue
    )
      .then((response) => {
        setRefundCaseData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function reset() {
    setToolcaseNo("");
    setTypeValue(0);
    setLocationValue("");
    setCurrentStageValue("");
    setStatusValue(5);
    setCompanyValue(0);
    getRefundToolCase();
  }

  function clickEditDocument(rowData) {
    let id = rowData.metaDataId;
    let idEncrypted = btoaSecure(id);

    if (
      rowData.taxProfessionId !== userDetails.userId &&
      userDetails.roleId === 2
    ) {
      return (
        <Tooltip title="No-Access">
          <span className="template-text">
            {rowData.refundCaseNo
              ? rowData.refundCaseNo
              : rowData.draftRefundCaseNo}
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="View Refund Case">
          <Link to={"/addRefund?id=" + idEncrypted}>
            <span className="template-text">
              {rowData.refundCaseNo
                ? rowData.refundCaseNo
                : rowData.draftRefundCaseNo}
            </span>
          </Link>
        </Tooltip>
      );
    }
  }

  const columns = [
    {
      title: "Refund Id",
      field: "refundCaseId",
      hidden: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Meta Data Id",
      field: "metaDataId",
      hidden: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Refund Case No",
      field: "refundCaseNo",
      hidden: true,
      cellStyle: {
        textAlign: "left",
      },
      export: true,
    },
    {
      title: "Draft Refund Case No",
      field: "draftRefundCaseNo",
      hidden: true,
      cellStyle: {
        textAlign: "left",
      },
      export: true,
    },
    {
      title: "Refund Case No",
      field: "toolCaseNo",
      align: "left",
      width: "10%",
      // render: (rowData) =>
      //   rowData.toolCaseNo ? rowData.toolCaseNo : rowData.draftToolCaseNo,
      render: (rowData) => rowData && clickEditDocument(rowData),
      export: false,
    },
    {
      title: "IDT Incharge",
      field: "taxProfessionalName",
      align: "left",
      width: "10%",
    },
    { title: "Entity", field: "entityName", align: "left", width: "12%" },
    {
      title: "Current Stage",
      field: "currentStageName",
      align: "left",
    },
    { title: "Location", field: "assessmentLocationName", align: "left" },
    {
      title: "Status",
      field: "approveStatus",
      align: "left",
      width: "13%",
      render: (rowData) => (
        <div>
          {(rowData.approveStatus === "Pending For Approval" &&
            (userDetails.roleId === 1 ||
              userDetails.roleId === 4 ||
              userDetails.roleId === 5)) ||
          (userDetails.roleId === 3 &&
            rowData.approveStatus === "Pending For Approval")
            ? ApproveDocumentButton(rowData)
            : rowData.approveStatus}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getRefundToolCase();
    getSubModule(IDTModuleId);
    getAllLocation();
    getCompany();
  }, []);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} className="text-left ml-1">
          <h3 className="m-0">Refund Case List</h3>
        </Grid>
        <Grid item xs={12} className="d-flex jc-flex-end">
          <a href="/addRefund" className={classes.aTag}>
            Add Refund &gt;&gt;
          </a>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={2} className="">
          <Grid container item xs={12} lg={10} spacing={2}>
            <Grid item xs={12} md={2}>
              <TextField
                select
                label="Refund Case Type"
                id=""
                name=""
                className=""
                fullWidth
                value={typeValue}
                onChange={handleTypeChange}
                variant="filled">
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Draft Refund Case No</MenuItem>
                <MenuItem value={2}>Refund Case No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Refund Case No"
                id=""
                name=""
                className=""
                fullWidth
                value={toolcaseNo}
                onChange={handleToolcaseNo}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                label="Entity"
                id=""
                name=""
                className=""
                fullWidth
                value={companyValue}
                onChange={handleCompanyChange}
                variant="filled">
                <MenuItem key={0} value={0}>
                  All
                </MenuItem>
                {companyData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                label="Location"
                id=""
                name=""
                className=""
                fullWidth
                value={locationValue}
                onChange={handleLocationChange}
                variant="filled">
                <MenuItem key={0} value={0}>
                  All
                </MenuItem>
                {location.map((item) => (
                  <MenuItem key={item.stateId} value={item.stateId}>
                    {item.state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                label="Stage"
                id=""
                name=""
                className=""
                fullWidth
                value={currentStageValue}
                onChange={handleCurrentStageChange}
                variant="filled">
                <MenuItem key={0} value={0}>
                  All
                </MenuItem>
                {currentStage.map((item) => (
                  <MenuItem key={item.subModuleId} value={item.subModuleId}>
                    {item.subModuleName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                label="Status"
                id=""
                name=""
                className=""
                fullWidth
                value={statusValue}
                onChange={handleStatus}
                variant="filled">
                <MenuItem key={5} value={5}>
                  All
                </MenuItem>
                <MenuItem key={4} value={4}>
                  Closed
                </MenuItem>
                <MenuItem key={0} value={0}>
                  Draft
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Pending for Approval
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Rejected
                </MenuItem>
                <MenuItem key={3} value={3}>
                  Approved
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container item xs={6} lg={2} spacing={2}>
            <Grid item xs={12} md={6} className="align-self">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={getFilterToolCase}
                fullWidth>
                Search
              </Button>
            </Grid>
            <Grid item xs={12} md={6} className="align-self">
              <Button
                type="submit"
                className="pt-button--secondary"
                id="SupplierLoginBtn"
                onClick={reset}
                fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="mt-1 toolcase-table mb-1">
          <MaterialTable
            title=""
            columns={columns}
            data={refundCaseData}
            icons={tableIconsInputScreen}
            options={{
              actionsColumnIndex: -1,
              search: false,
              //toolbar: false,
              pageSize: 10,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20, 30, 50],
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportFileName: "RefundCaseList",
              exportAllData: true,
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
            actions={[
              (rowData) => ({
                icon: VisibilityIcon,
                isFreeAction: true,
                tooltip:
                  rowData.taxProfessionId !== userDetails.userId &&
                  userDetails.roleId === 2
                    ? `No Access`
                    : `View Refund Case Details`,
                onClick: (event, rowData) => viewToolCaseData(rowData),
                disabled:
                  rowData.taxProfessionId !== userDetails.userId &&
                  userDetails.roleId === 2,
              }),
              (rowData) => ({
                icon: HistoryIcon,
                isFreeAction: true,
                tooltip:
                  rowData.taxProfessionId !== userDetails.userId &&
                  userDetails.roleId === 2
                    ? `No Access`
                    : `View Workflow History`,
                onClick: (event, rowData) => viewWorkflow(rowData),
                disabled:
                  rowData.taxProfessionId !== userDetails.userId &&
                  userDetails.roleId === 2,
              }),
            ]}
            localization={{
              header: {
                actions: "View Details",
              },
            }}
          />
        </Grid>
      </Grid>
      {/* Reject dialog */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseForm(event, reason);
          }
        }}
        aria-labelledby="litigation-form--registration"
        open={openForm}
        maxWidth={"md"}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2}>
              <h5>Please provide reason to reject the Refund Case.</h5>
              <Grid item xs={12}>
                <TextField
                  label="Reason"
                  id=""
                  name=""
                  className=""
                  fullWidth
                  value={rejectReason}
                  onChange={handleRejectReason}
                  variant="filled"></TextField>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            onClick={handleCloseForm}>
            close
          </Button>
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            disabled={!rejectReason}
            onClick={handleRejectToolCase}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseFormApprove(event, reason);
          }
        }}
        aria-labelledby="litigation-form--registration"
        open={openFormApprove}
        maxWidth={"md"}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h5>Do You Want To Approve Refund Case?</h5>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            onClick={handleCloseFormApprove}>
            close
          </Button>
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            onClick={handleApproveToolCase}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      <>
        <SnackbarCustom
          open={openSnackbar}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleCloseSnackbar}
        />
      </>
    </div>
  );
}

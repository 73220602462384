import { baseApiURL } from "../../Utilities/Utility";

//? Get Apis - Dropdown

export async function getCountryList(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetCountryList",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getStateList(siteToken, countryId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetStateList?countryId=" + countryId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getCompanyList(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetMstCompanyData",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getCompanyListById(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetMstCompanyData?companyId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getTypeOfRegList(siteToken, subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetTypeOfRegList?subModuleId=" + subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getMergedEntityList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetMergeEntityData?companyId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//? Get list Methods

export async function getDivisionMasterList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetDivisionList?divisionId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getBUMasterList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetBUList?buId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getBSMasterList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetBSList?bsId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getCompanyGstList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetMstCompanyGstData?companyId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getCostCenterList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetCostCenterList?costCenterId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//* Post Methods

export async function addCompanyMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/SaveAndUpdateComapnyMstData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function addStateForCompany(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/SaveAndUpdateComapnyMstGstData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function addDivisionMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Master/SaveDivision", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function addBUMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Master/SaveBU", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function addBSMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Master/SaveBS", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function addCostCenterMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/SaveCostCenter",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function addLinkedMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/InsertHardCodeMasterData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//! Linked Master APIs

export async function getLinkedMasterList(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/LinkedMaster/GetLinkedMasterList",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getLinkedMasterListById(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/LinkedMaster/GetLinkedMasterList?LinkedMasterId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getMasterKeyList(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/LinkedMaster/GetLinkedMasterKey",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getSubMasterKeyList(siteToken, masterKeyId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/LinkedMaster/GetLinkedSubMasterKey?MasterKeyId=" +
        masterKeyId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getMasterNameList(siteToken, moduleId, masterKeyId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/LinkedMaster/GetLinkedMasterName?ModuleId=" +
        moduleId +
        "&MasterKeyId=" +
        masterKeyId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function insertLinkedMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/LinkedMaster/InsertLinkedMasterData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function UpdateLinkedMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/LinkedMaster/UpdateLinkedMasterData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//! Edit Methods
export async function editDivisionMaster(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Master/EditDivision", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function editBUMaster(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Master/EditBU", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function editBSMaster(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Master/EditBS", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function editCostCenterMaster(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/EditCostCenter",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

import { baseApiURL } from "../../Utilities/Utility";

export function getCompany(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/Master/GetCompanyList", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getIssueList(siteToken, moduleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Master/GetIssueList?moduleId=" + moduleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getIssueDescriptionList(siteToken, issueId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Master/GetIssueDescriptionList?issueId=" + issueId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getYear(siteToken, fromDate, toDate) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL +
      "/Master/GetFinancialYears?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getEnquiryList(siteToken, moduleId, subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL +
      "/Master/GetEnquiryType?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getCommunicationModeList(siteToken, moduleId, subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL +
      "/Master/GetModeOfDepartmentCommunication?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getCommunicationTypeList(siteToken, moduleId, subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL +
      "/Master/GetTypeOfDepartmentCommunication?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getEnquiryStatusList(siteToken, moduleId, subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL +
      "/Master/GetIssueStatus?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getLocationList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/Master/GetLocationState?companyId=" + id, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getTaxProfessionalList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Master/GetTaxProfessional?moduleId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getTaxManagerList(siteToken, id, profId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Master/GetTaxManager?moduleId=" + id + "&userId=" + profId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getGSTINList(siteToken, stateId, companyId, regId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstCompanyGst?stateId=" +
      stateId +
      "&companyId=" +
      companyId +
      "&typeOfReg=" +
      regId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//Post Method
//? Part 1
export function uploadToolCaseData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/SaveIdtEnquiryDetails_Part1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function editToolCaseFormData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/EditIdtEnquiryFormDetails_Part1",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function editToolCaseGridData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/EditIdtEnquiryGridDetails_Part1",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function addToolCaseGridData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/SaveIdtEnquiryGridDetails_Part1",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//? Part 2
export function uploadToolCasePart2FormData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/SaveIdtEnquiryFormDetails_Part2",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function uploadToolCasePart2GridData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/SaveIdtEnquiryGridDetails_Part2",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function editToolCasePart2GridData(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/EditIdtEnquiryGridDetails_Part2",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function editToolCasePart2FormData(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/EditIdtEnquiryFormDetails_Part2",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//? Get Methods
export function getIdtSCNGridDetailPart2(siteToken, scnId) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNGridDetailPart2?SCNId=" + scnId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getIdtSCNGridDetailPart3(siteToken, scnId) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNGridDetailPart3?SCNId=" + scnId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getIdtSCNFormDataPart3(siteToken, scnId) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNFormDataPart3?SCNId=" + scnId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//! New APi calls
export function SaveMetaData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/SaveMetadata", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveIssueList(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/IdtEnquiry/SaveEnquiryIssuePart1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveModeOfComm(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/SaveModeOfComm", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function GetGeneralEnqFormDetails(siteToken, toolcaseId) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/GetIdtEnquiryFormDetails?ToolCaseId=" +
      toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function GetIssueGridDetails(siteToken, toolcaseId) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/GetEnquiryIssueInvoled?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function GetCommunicationGridDetails(siteToken, toolcaseId) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/GetIdtEnquiryGridDetails?ToolCaseId=" +
      toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//* Put methods
export function editGeneralEnqFormData(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditEnquiryPart1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function editIssueGrid(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditIssueInvolved", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function editModeOfCommGrid(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditModeOfComm", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//! new update methods

export function UpdateMetaDataDetails(formData, siteToken) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditEnquiryPart1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//import { baseAddressURL } from "../../../Utilities/Utility";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  getBSList,
  getBUList,
  getCompanyList,
  getDivisionList,
} from "../../../Redux/API/api_userManagement";
import {
  addCostCenterMaster,
  editCostCenterMaster,
  getCostCenterList,
} from "../../../Redux/API/api_master";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddCostCenter() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add Cost Center");
  const [data, setData] = useState([]);
  const [costCenter, setCostCenter] = useState("");
  const [costCenterId, setCostCenterId] = useState("");
  const [costCenterHelper, setCostCenterHelper] = useState("");
  const [company, setCompany] = useState([]);
  const [companyValue, setCompanyValue] = useState("");
  const [division, setDivision] = useState([]);
  const [divisionValue, setDivisionValue] = useState("");
  const [bu, setBU] = useState([]);
  const [buValue, setBUValue] = useState("");
  const [bs, setBS] = useState([]);
  const [bsValue, setBSValue] = useState("");
  const [statusValue, setStatusValue] = useState(true);
  let [isLoader, setIsLoader] = useState(false);

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/costCenter";
    }
  };

  //! Dropdown functions
  const handleChangeCompany = (e) => {
    setCompanyValue(e.target.value);
    getDivision(e.target.value);
    setDivisionValue("");
    setBUValue("");
    setBSValue("");
  };
  const handleChangeDivision = (e) => {
    setDivisionValue(e.target.value);
    getBU(e.target.value);
    setBUValue("");
    setBSValue("");
  };
  const handleChangeBu = (e) => {
    setBUValue(e.target.value);
    getBS(e.target.value);
    setBSValue("");
  };
  const handleChangeBs = (e) => {
    setBSValue(e.target.value);
  };
  const handleChangeCostCenter = (e) => {
    setCostCenter(e.target.value);
    let valid = /^[a-zA-Z0-9 _-]*$/.test(e.target.value);
    if (!valid) {
      setCostCenterHelper("Please enter valid values.");
    } else if (e.target.value.length < 1) {
      setCostCenterHelper("Minimum 2 characters required");
    } else {
      setCostCenterHelper("");
    }
  };

  const handleChangeStatus = (e) => {
    setStatusValue(e.target.value);
  };

  //! Dropdown Get Methods

  function getCompany() {
    getCompanyList(siteToken)
      .then((response) => {
        setCompany(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getDivision(id) {
    getDivisionList(siteToken, id)
      .then((response) => {
        setDivision(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getBU(id) {
    getBUList(siteToken, id)
      .then((response) => {
        setBU(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getBS(id) {
    getBSList(siteToken, id)
      .then((response) => {
        setBS(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  var formdata = {
    costCenter: costCenter,
    companyId: parseInt(companyValue),
    divisionId: parseInt(divisionValue),
    buId: parseInt(buValue),
    bsId: parseInt(bsValue),
    status: statusValue,
    createdBy: userDetails.userId,
  };

  var formdataEdit = {
    costCenterId: costCenterId,
    costCenter: costCenter,
    companyId: parseInt(companyValue),
    divisionId: parseInt(divisionValue),
    buId: parseInt(buValue),
    bsId: parseInt(bsValue),
    status: statusValue,
    createdBy: userDetails.userId,
  };

  //! Add Cost Center
  function addCostCenter() {
    setIsLoader(true);
    addCostCenterMaster(formdata, siteToken)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Cost Center Added Successfully.", "success");
          setIsLoader(false);
          //reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Cost Center Already Exists.", "warning");
          //reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Cost Center.", "error");
          //reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }
  function editCostCenter() {
    setIsLoader(true);
    editCostCenterMaster(formdataEdit, siteToken)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Cost Center Edited Successfully.", "success");
          setIsLoader(false);
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Cost Center Already Exists.", "warning");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Editing Cost Center.", "error");
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");
  function getCostCenterById() {
    setIsLoader(true);
    getCostCenterList(siteToken, atobSecure(encryptedId))
      .then((response) => {
        setCostCenter(response[0].costCenter);
        setCompanyValue(response[0].companyId);
        getDivision(response[0].companyId);
        setDivisionValue(response[0].divisionId);
        getBU(response[0].divisionId);
        setBUValue(response[0].buId);
        getBS(response[0].buId);
        setBSValue(response[0].bsId);
        setStatusValue(response[0].status);
        setCostCenterId(response[0].costCenterId);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (siteToken) {
      getCompany();
    }
    if (encryptedId) {
      setPageHeader("Edit Cost Center");
      getCostCenterById();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>
      <div className="box-shadow">
        <Grid
          container
          spacing={2}
          direction="row"
          className="upload-block pt-1">
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Cost Center"
              id="costCenter"
              name="costCenter"
              className=""
              value={costCenter}
              onChange={handleChangeCostCenter}
              helperText={costCenterHelper}
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Company"
              select
              id="company"
              name="company"
              className=""
              fullWidth
              variant="filled"
              value={companyValue}
              onChange={handleChangeCompany}>
              {company.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Division"
              select
              id="division"
              name="division"
              className=""
              fullWidth
              variant="filled"
              value={divisionValue}
              onChange={handleChangeDivision}>
              {division.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Business Unit"
              select
              id="bu"
              name="bu"
              className=""
              fullWidth
              variant="filled"
              value={buValue}
              onChange={handleChangeBu}>
              {bu.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Business Segment"
              select
              id="bs"
              name="bs"
              className=""
              fullWidth
              variant="filled"
              value={bsValue}
              onChange={handleChangeBs}>
              {bs.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              select
              label="Status"
              id="status"
              name="Status"
              className=""
              fullWidth
              value={statusValue}
              onChange={handleChangeStatus}
              variant="filled">
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="upload-block d-flex jc-center pb-2">
          {encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={editCostCenter}
                disabled={
                  !costCenter ||
                  !companyValue ||
                  !divisionValue ||
                  !buValue ||
                  !bsValue
                }
                fullWidth>
                Update
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {!encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={addCostCenter}
                disabled={
                  !costCenter ||
                  !companyValue ||
                  !divisionValue ||
                  !buValue ||
                  !bsValue
                }
                fullWidth>
                Submit
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={1} className="align-center width-100">
            <Link to={"/costCenter"}>
              <Button
                type="submit"
                className="pt-button--secondary"
                id="SupplierLoginBtn"
                fullWidth>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
        {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      </div>

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector } from "react-redux";
import {
  getRefundWorkflowHistory,
  getWorkflowHistory,
} from "../../Redux/API/api_toolCaseList";
import Loader from "../../Components/Loader/Loader";

export default function WorkflowHistory() {
  const [data, setData] = useState([]);
  const [toolcaseNo, setToolcaseNo] = useState("");
  let [isLoader, setIsLoader] = useState(false);
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const isRefundCase = useSelector((state) => state.saveRefundCaseValue.value);
  var encryptedId = getURLParameter("id");
  const columnsForm = [
    { title: "S.No.", field: "serialNo", width: "8%" },
    { title: "Module", field: "subModuleName", width: "8%" },
    {
      title: "Action Owner Email",
      field: "emailId",
      hidden: isRefundCase ? true : false,
    },
    {
      title: "Action Owner Email",
      field: "createdByEmail",
      hidden: isRefundCase ? false : true,
    },
    { title: "Status", field: "currentWorkStatusName" },
    {
      title: "Action Taken On",
      field: "createdDateString",
    },
    { title: "Remarks", field: "comments" },
  ];
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }

  function getWorkflow() {
    setIsLoader(true);
    var id = atobSecure(encryptedId);
    getWorkflowHistory(siteToken, id)
      .then((response) => {
        setData(response);
        setToolcaseNo(response[0].toolCaseNo);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  function getRefundWorkflow() {
    setIsLoader(true);
    var id = atobSecure(encryptedId);
    getRefundWorkflowHistory(siteToken, id, isRefundCase)
      .then((response) => {
        setData(response);
        setToolcaseNo(response[0].refundCaseNo);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (encryptedId) {
      //getWorkflow();
      isRefundCase === true ? getRefundWorkflow() : getWorkflow();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className="">
      <Grid container spacing={2} className="">
        <h3>
          {isRefundCase === true ? "Refund Case No :- " : "Tool Case No :- "}
          {toolcaseNo}
        </h3>
        <Grid item xs={12} className="workflow-table">
          <MaterialTable
            title="Workflow History"
            columns={columnsForm}
            data={data}
            icons={tableIconsInputScreen}
            options={{
              emptyRowsWhenPaging: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 50],
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportAllData: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={1} className="align-self">
          <Link to={isRefundCase ? "/refundList" : "/ViewToolCase"}>
            <Button
              type="submit"
              fullWidth
              className="pt-button--secondary"
              id="SupplierLoginBtn">
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}

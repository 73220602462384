import { baseApiURL } from "../../Utilities/Utility";

export function getToolCaseList(
  siteToken,
  statusId,
  toolcaseNo,
  locationId,
  subModuleId,
  toolCaseStatus,
  tagId,
  companyID
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/GetIdtToolCaseList?toolCaseType=" +
      statusId +
      "&toolCaseNumber=" +
      toolcaseNo +
      "&locationId=" +
      locationId +
      "&currentStageId=" +
      subModuleId +
      "&toolCaseStatus=" +
      toolCaseStatus +
      "&tagId=" +
      tagId +
      "&companyID=" +
      companyID,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getToolCaseListbyId(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/GetIdtEnquiryFormDetails?ToolCaseId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getToolCaseTableListbyId(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/GetIdtEnquiryGridDetails?ToolCaseId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getAllLocationList(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/Master/GetAllLocation", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getSummaryReportRemarks(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Common/GetSummaryReportRemark?toolCaseID=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSummaryReportLog(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Common/GetSummaryReportRemarkLog?toolCaseId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}


export function getAllTagList(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/Master/GetTagList", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getTagBasedOnToolCase(siteToken, toolcaseId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Master/GetTagData?toolCaseID=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//!Post Method
export function approveToolCaseData(siteToken, id, userId) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/ApproveIdtEnquiryDetails?ToolCaseId=" +
      id +
      "&UserId=" +
      userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function rejectToolCaseData(siteToken, id, reason, userId) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/RejectIdtEnquiryDetails?ToolCaseId=" +
      id +
      "&RejectionReason=" +
      reason +
      "&UserId=" +
      userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//? Workflow History
export function getWorkflowHistory(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Master/GetWorkFlowStatusDetails?toolCaseID=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getRefundWorkflowHistory(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Refund/GetRefundWorkFlowStatus?refundCaseID=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//? Change Status api
export function ChangeStatusOfToolCase(siteToken, id) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/ChangeApprovalStatus?ToolCaseId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//! Save/Edit Tag
export function saveTag(siteToken, tags, id, userId) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/SaveAndUpdateTagMapping?tags=" +
      tags +
      "&toolCaseId=" +
      id +
      "&userId=" +
      userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
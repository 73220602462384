import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
  IDTModuleId,
  baseApiURL,
  tableIconsInputScreen,
} from "../../Utilities/Utility";
import { getEnquiryStatusList } from "../../Redux/API/api_createEnquiry";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import {
  GetFormAdjustmentList,
  GetLiabilityAcceptedList,
  InsertFormAdjustment,
  addIssueLevelDetails,
  getAppealStageList,
  getIssueLevelAllData,
  getRemandAuthorityList,
} from "../../Redux/API/api_events";
import {
  getAppealPreferredList,
  getOrderSummaryList,
  getPPRList,
} from "../../Redux/API/api_scn";
import FormAdjustmentTemplate from "../../assets/template/UploadTemplate/Form_adjustment_upload_template.xlsx";
import {
  GetNextAppealStageList,
  GetRemandAuthorityList,
  addRefundIssueLevelDetails,
  GetRefundIssueDetails,
} from "../../Redux/API/api_refund";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
    // borderBottom: `2px solid #00BCBC`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    //borderBottom: `2px solid #00BCBC`,
  },
  header: {
    margin: `0`,
  },
  rejectDiv: {
    color: `red`,
  },
  errorMsg: {
    color: `red !important`,
    marginTop: `4px`,
    marginBottom: `4px`,
    borderBottom: `2px solid red`,
  },
  mandatoryNote: {
    color: `red !important`,
    fontSize: `14import { baseApiURL } from './../../Utilities/Utility';
px`,
    fontStyle: `italic`,
    display: `flex`,
    justifyContent: `flex-end`,
    marginBottom: `0`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    paddingBottom: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
  aTagTemplate: {
    color: `#00BCBC`,
    fontStyle: `italic`,
    borderBottom: `1px solid #00BCBC`,
    fontWeight: `bold`,
    width: `fit-content`,
    padding: `0`,
    margin: `0`,
  },
  errorTag: {
    color: `#FF5454`,
    fontStyle: `italic`,
    borderBottom: `1px solid #FF5454`,
    fontWeight: `bold`,
    width: `fit-content`,
    padding: `0`,
    margin: `0`,
  },
}));

export default function RefundIssueDetails() {
  const classes = useStyles();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  var encryptedId = getURLParameter("id");
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const issueData = useSelector(
    (state) => state.storeRefundIssueDetailsReducer.issueData
  );
  const toolCaseDetails = useSelector(
    (state) => state.saveToolCaseNo.toolCaseNo
  );
  const isRefundCaseClosed = useSelector(
    (state) => state.saveRefundCaseStatus.value
  );
  const [draftNo, setDraftNo] = useState("");
  const [toolCaseNo, setToolCaseNo] = useState("");
  //* Input Hooks
  const [currentStage, setCurrentStage] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [orderSummary, setOrderSummary] = useState([]);
  const [orderSummaryValue, setOrderSummaryValue] = useState("");
  const [status, setStatus] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [statusName, setStatusName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [appealPref, setAppealPref] = useState([]);
  const [appealPrefValue, setAppealPrefValue] = useState("");
  const [appealStage, setAppealStage] = useState([]);
  const [appealStageValue, setAppealStageValue] = useState("");
  const [remandAuthority, setRemandAuthority] = useState([]);
  const [remandAuthorityValue, setRemandAuthorityValue] = useState("");
  const [appealStageName, setAppealStageName] = useState("");
  const [currentStageName, setCurrentStageName] = useState("");
  const [currentStageId, setCurrentStageId] = useState("");
  const [remandAuthorityName, setRemandAuthorityName] = useState("");
  //? Snackbar & Loaders
  let [isLoader, setIsLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (alertType === "success") {
      window.location.reload();
    }
  };

  const handleChangeOrderNo = (event) => {
    setOrderNo(event.target.value);
  };

  const handleChangeOrderSummary = (event) => {
    setOrderSummaryValue(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setAppealStageValue("");
    setRemandAuthorityValue("");
    setStatusValue(event.target.value);
    var statusName = status.filter((c) => c.hardCodeId === event.target.value);
    setStatusName(statusName[0].name);
  };
  const handleChangeAppealPref = (event) => {
    setAppealPrefValue(event.target.value);
  };
  const handleChangeAppealStage = (event) => {
    setAppealStageValue(event.target.value);
  };
  const handleChangeRemandAuthority = (event) => {
    setRemandAuthorityValue(event.target.value);
  };

  function getIssueStatus() {
    getEnquiryStatusList(siteToken, IDTModuleId, issueData.subModuleId)
      .then((response) => {
        setStatus(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getOrderSummary() {
    getOrderSummaryList(siteToken, IDTModuleId, issueData.subModuleId)
      .then((response) => {
        setOrderSummary(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getAppealPreferred() {
    getAppealPreferredList(siteToken, IDTModuleId, issueData.subModuleId)
      .then((response) => {
        setAppealPref(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getAllIssueDetails(id) {
    GetRefundIssueDetails(siteToken, id)
      .then((response) => {
        setOrderNo(response.orderNumber);
        setOrderSummaryValue(
          response.orderSummary !== null ? response.orderSummary : ""
        );
        setAppealPrefValue(
          response.appealPreferred !== null ? response.appealPreferred : ""
        );
        setStatusValue(response.issueLevelStatus);
        setStatusName(response.issueLevelStatusValue);
        if (response.issueLevelStatusValue !== "Open") {
          setIsDisabled(true);
        }
        setAppealStageValue(response.nextAppealStage);
        setAppealStageName(
          response.nextAppealStageValue ? response.nextAppealStageValue : ""
        );
        setRemandAuthorityValue(
          response.remandAuthority ? response.remandAuthority : ""
        );
        setRemandAuthorityName(response.remandAuthorityValue);
        setCurrentStage(response.currentStageValue);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getRemandAuthority() {
    GetRemandAuthorityList(siteToken, issueData.issueId)
      .then((response) => {
        setRemandAuthority(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getAppealStage() {
    GetNextAppealStageList(siteToken, issueData.issueId)
      .then((response) => {
        setAppealStage(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // ! Encrypt Decrypt Methods

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }
  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        // return sParameterName[1];
      }
      return value;
    }
  }
  //let encryptedId = getURLParameter("id");

  //! Post Method

  var formdata = {
    id: issueData.issueId,
    orderNumber: orderNo,
    orderSummary: orderSummaryValue ? orderSummaryValue : 0,
    issueLevelStatus: statusValue,
    appealPreferred: appealPrefValue ? appealPrefValue : 0,
    nextAppealStage: appealStageValue ? appealStageValue : 0,
    remandAuthority: remandAuthorityValue ? remandAuthorityValue : 0,
    modifiedBy: userDetails.userId,
  };
  function addIssueDetails() {
    setIsLoader(true);
    addRefundIssueLevelDetails(formdata, siteToken)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Issue Details Added Successfully.", "success");
          setIsLoader(false);
        } else if (response.status === 4) {
          setIsLoader(false);
          handleSnackOpen(response.result, "error");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Issue Details.", "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getIssueStatus();
    getOrderSummary();
    getAppealPreferred();
    getAppealStage();
    getRemandAuthority();
    getAllIssueDetails(issueData.issueId);
    setToolCaseNo(toolCaseDetails.approved);
    setDraftNo(toolCaseDetails.draft);
  }, []);

  return (
    <div className="">
      <Grid container spacing={2} className="m-0">
        <Grid item xs={12} md={12}>
          {statusName === "Remanded" ? (
            <p className={classes.mandatoryNote}>
              Remand Authority Is Mandatory
            </p>
          ) : (
            <></>
          )}
          {statusName === "Higher Appeal" ? (
            <p className={classes.mandatoryNote}>
              Next Appeal Stage Is Mandatory
            </p>
          ) : (
            <></>
          )}
          {orderNo && statusName === "Open" ? (
            <p className={classes.mandatoryNote}>
              Order Number Received, Change Issue Status
            </p>
          ) : (
            <></>
          )}
          {!orderNo && statusName !== "Open" ? (
            <p className={classes.mandatoryNote}>
              Issue Status Changed, Fill Order Number
            </p>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <h4 className="m-0">
            Refund Case No:- {toolCaseNo ? toolCaseNo : draftNo}
          </h4>
        </Grid>
        <Grid item xs={12} md={6} className="d-flex jc-flex-start">
          <h4 className="m-0">
            Issue Description Name:- {issueData.briefDescName}
          </h4>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.subGrid}>
        <Grid item xs={12}>
          <h4 className={classes.header}>Add Order No</h4>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Current Stage"
            required
            id=""
            name=""
            className=""
            fullWidth
            disabled
            variant="filled"
            value={currentStage}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Order Number"
            required
            id=""
            name=""
            className=""
            fullWidth
            variant="filled"
            value={orderNo}
            onChange={handleChangeOrderNo}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.subGrid}>
        <Grid item xs={12}>
          <h4 className={classes.header}>Issue Level Details</h4>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Order Summary"
            id=""
            name=""
            className=""
            fullWidth
            select
            required={statusName !== "Open" ? true : false}
            variant="filled"
            value={orderSummaryValue}
            onChange={handleChangeOrderSummary}>
            {orderSummary.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Appeal Preferred"
            id=""
            name=""
            className=""
            fullWidth
            select
            required={statusName !== "Open" ? true : false}
            variant="filled"
            value={appealPrefValue}
            onChange={handleChangeAppealPref}>
            {appealPref.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Status"
            id=""
            name=""
            className=""
            fullWidth
            variant="filled"
            value={statusValue}
            select
            disabled={isDisabled}
            onChange={handleChangeStatus}>
            {status.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {issueData.subModuleName === "Refund-Appeal" ? (
          <>
            <Grid item xs={12} md={3}>
              <TextField
                label="Next Appeal Stage"
                id=""
                name=""
                className=""
                fullWidth
                select
                disabled={statusName !== "Higher Appeal" ? true : false}
                variant="filled"
                value={appealStageValue}
                onChange={handleChangeAppealStage}>
                {appealStage.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Remand Authority"
                id=""
                name=""
                className=""
                fullWidth
                select
                disabled={statusName !== "Remanded" ? true : false}
                variant="filled"
                value={remandAuthorityValue}
                onChange={handleChangeRemandAuthority}>
                {remandAuthority.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>

      <Grid
        container
        item
        xs={12}
        spacing={3}
        className="mt-1 d-flex jc-center">
        {userDetails.roleId === 2 || userDetails.roleId === 1 ? (
          <Grid item xs={12} md={1} className="align-self">
            <Button
              type="submit"
              fullWidth
              className="pt-button--primary"
              disabled={
                isRefundCaseClosed === true ||
                (orderNo && statusName === "Open") ||
                (!orderNo && statusName !== "Open") ||
                (statusName === "Remanded" && !remandAuthorityValue) ||
                (statusName === "Higher Appeal" && !appealStageValue) ||
                (!appealPrefValue && statusName !== "Open") ||
                (!orderSummaryValue && statusName !== "Open")
                  ? true
                  : false
              }
              onClick={addIssueDetails}
              id="SupplierLoginBtn">
              Save
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={1} className="align-self">
          <Link to={"/addRefund?id=" + btoaSecure(issueData.metaDataId)}>
            <Button
              type="submit"
              fullWidth
              className="pt-button--secondary"
              id="SupplierLoginBtn">
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>

      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { getCFRReportList } from "../../Redux/API/api_reports";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { getAllLocationList } from "../../Redux/API/api_toolCaseList";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getCompany } from "../../Redux/API/api_createEnquiry";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
    //borderBottom: `2px solid #00BCBC`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
}));
export default function CFRReport() {
  const classes = useStyles();
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const [cfrData, setCFRData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);
  const [typeValue, setTypeValue] = useState(0);
  const [company, setCompany] = useState([]);
  const [companyValue, setCompanyValue] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [minDate, setMinDate] = useState(null);

  const handleTypeChange = (e) => {
    setTypeValue(e.target.value);
    //getCFRReport(e.target.value);
  };
  const handleCompany = (e) => {
    setCompanyValue(e.target.value);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date.toISOString());
    setMinDate(date.toISOString());
  };
  const handleToDateChange = (date) => {
    setToDate(date.toISOString());
  };

  const columnsForm = [
    {
      title: "Tool Case No",
      field: "litigationToolCaseNo",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "CFR No",
      field: "cfrToolCaseNo",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    { title: "Entity/Merged Entity Name", field: "entityMergedEntityName" },
    { title: "Assesment Location", field: "assessmentLocation" },
    { title: "Assesment year", field: "assessmentYear" },
    { title: "Nature Of Tax", field: "natureOfTax" },
    { title: "Current Authority", field: "currentAuthority" },
    { title: "SCN/Order/Appeal Order No", field: "scnOrderAppealOrderNo" },
    { title: "Basic Tax", field: "basicTax" },
    { title: "Ecess and SHEcess and Others", field: "ecessSHEcessAndOthers" },
    { title: "Interest", field: "interest" },
    { title: "Penalty", field: "penalty" },
    { title: "Total Amount", field: "totalDisputedAmount" },
    {
      title: "Brief Description",
      field: "briefDescription",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    { title: "PPR", field: "ppr" },
    {
      title: "Issue",
      field: "issueInvolved",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Appeal Stage",
      field: "appealStage",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    { title: "Total Pre-Deposit", field: "totalPreDeposit" },
    { title: "Pre-Deposit Recoverable", field: "preDepositRecoverable" },
    { title: "Provision Amt", field: "provisionAmt" },
    {
      title: "Latest Hearing Date",
      field: "latestHearingDate",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    { title: "Latest Hearing Status", field: "latestHearingStatus" },
    {
      title: "Section under which Notice/SCN issued",
      field: "sectionUnderWhichNoticeSCNIssued",
    },
    {
      title: "Date of SCN/Order/Appeal Order N0",
      field: "dateOfSCNOrderAppealOrderNo",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    { title: "Consultant Name", field: "consultantName" },
    { title: "CFT-Responsible Person", field: "cftResponsiblePerson" },
  ];
  function getCompanyList() {
    getCompany(siteToken)
      .then((response) => {
        setCompany(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getCFRReport() {
    setIsLoader(true);
    getCFRReportList(siteToken, companyValue, fromDate, toDate, typeValue)
      .then((response) => {
        if (response !== null) {
          setCFRData(response);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function resetCFRReport() {
    setIsLoader(true);
    setFromDate(null);
    setMinDate(null);
    setToDate(null);
    setCompanyValue(0);
    setTypeValue(0);
    getCFRReportList(siteToken, 0, null, null, 0)
      .then((response) => {
        if (response !== null) {
          setCFRData(response);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getCFRReport(typeValue);
    getCompanyList();
  }, [typeValue]);

  return (
    <div className="mt-1">
      {/* <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex fd-column">
          <h3 className={classes.sectionHeader}>CFR Report</h3>
        </Grid>
      </Grid> */}
      <Grid container spacing={2} className="mt-1">
        <Grid container item sx={12} md={10} spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              select
              label="Report Type"
              id=""
              name=""
              className=""
              fullWidth
              value={typeValue}
              onChange={handleTypeChange}
              variant="filled">
              <MenuItem value={0}>CFR Report</MenuItem>
              <MenuItem value={1}>All</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              select
              label="Company"
              id=""
              name=""
              className=""
              fullWidth
              value={companyValue}
              onChange={handleCompany}
              variant="filled">
              <MenuItem key={0} value={0}>
                All
              </MenuItem>
              {company.map((item) => (
                <MenuItem key={item.companyId} value={item.companyId}>
                  {item.companyName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="fromPeriod"
                className="datepicker"
                label="From Period Involved"
                              format="dd/MM/yyyy"
                             
                fullWidth
                value={fromDate}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{ readOnly: true }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="fromPeriod"
                className="datepicker"
                label="To Period Involved"
                              format="dd/MM/yyyy"
                              
                fullWidth
                value={toDate}
                onChange={handleToDateChange}
                //minDate={minDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{ readOnly: true }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container item sx={12} md={2} spacing={2} className="align-self">
          <Grid item xs={12} md={6} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              fullWidth
              onClick={getCFRReport}>
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md={6} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth
              onClick={resetCFRReport}>
              Reset
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} className="cfr-table">
          <MaterialTable
            title=""
            columns={columnsForm}
            data={cfrData}
            icons={tableIconsInputScreen}
            options={{
              emptyRowsWhenPaging: false,
              pageSize: 10,
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportFileName: "CFRReport",
              exportAllData: true,
              pageSizeOptions: [10, 20, 50],
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
          />
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}

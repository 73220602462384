import { baseApiURL } from "../../Utilities/Utility";

//USER MANAGEMENT APIs
export function getUserList(siteToken, userId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/UserManagement/GetAllUserList?UserId=" + userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Role
export function getRoleList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/Master/GetMstRoleList?moduleId=" + id, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Manager
export function getDefaultManagerList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/UserManagement/GetDefaultMngrList?ModuleId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Company
export function getCompanyList(siteToken) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/UserManagement/GetCompanyList", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Division
export function getDivisionList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/UserManagement/GetDivisionByCompanyId?CompanyId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//BU
export function getBUList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/UserManagement/GetBUListByDivision?DivisionId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//BS
export function getBSList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/UserManagement/GetBSListByBU?BuId=" + id, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Add User Details
export function addUserData(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(
    baseApiURL + "/UserManagement/SaveUserManagementDetails",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

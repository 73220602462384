import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import {
  getModuleList,
  getAllSubModuleList,
  addIssueMaster,
  editIssueMaster,
  getIssueMasterList,
} from "../../../Redux/API/api_admin";
import {
  baseAddressURL,
  CreateEnqModuleId,
  IDTModuleId,
  tableIconsInputScreen,
} from "../../../Utilities/Utility";
import { getIssueList } from "../../../Redux/API/api_createEnquiry";
import {
  getLinkedMasterListById,
  getMasterKeyList,
  getMasterNameList,
  getSubMasterKeyList,
  insertLinkedMaster,
  UpdateLinkedMaster,
} from "../../../Redux/API/api_master";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddIssueMaster() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add Linked Master");
  const [data, setData] = useState([]);
  const [masterId, setMasterId] = useState("");
  const [moduleId, setModuleId] = useState([]);
  const [moduleValue, setModuleIdValue] = useState("");
  const [subModuleId, setSubModuleId] = useState([]);
  const [moduleIdHelper, setModuleIdHelper] = useState("");
  const [masterKey, setMasterKey] = useState([]);
  const [masterKeyValue, setMasterKeyValue] = useState("");
  const [subMasterKey, setSubMasterKey] = useState([]);
  const [subMasterKeyValue, setSubMasterKeyValue] = useState("");
  const [masterName, setMasterName] = useState([]);
  const [masterNameValue, setMasterNameValue] = useState("");
  const [subMasterName, setSubMasterName] = useState("");
  const [newMasterName, setNewMasterName] = useState("");
  const [masterNameHelper, setMasterNameHelper] = useState("");
  const [subMasterNameHelper, setSubMasterNameHelper] = useState("");
  let [isLoader, setIsLoader] = useState(false);
  const [status, setStatus] = useState(true);

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  var encryptedId = getURLParameter("id");
  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/linkedMasterList";
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleChangeNewMasterName = (e) => {
    setNewMasterName(e.target.value);
    // if (e.target.value && encryptedId) {
    //   setMasterNameValue("");
    // }
    if (e.target.value.length === 0) {
      setMasterNameHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setMasterNameHelper("Length cannot exceed more than 200.");
    } else {
      setMasterNameHelper("");
    }
  };
  const handleChangeSubMaster = (e) => {
    setSubMasterName(e.target.value);
    if (e.target.value.length === 0) {
      setSubMasterNameHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setSubMasterNameHelper("Length cannot excced more than 200.");
    } else {
      setSubMasterNameHelper("");
    }
  };

  const handleChangeModule = (e) => {
    //setSubModuleIdValue("");
    setMasterNameValue("");
    setModuleIdValue(e.target.value);
    if (masterKeyValue) {
      getMasterName(e.target.value, masterKeyValue);
    }

    // getSubModule(e.target.value);
    // if (subModuleIdValue) {
    //   getMasterKey(e.target.value, subModuleIdValue);
    // }
    // if (subModuleIdValue && masterKeyValue) {
    //   getMasterName(e.target.value, subModuleIdValue, masterKeyValue);
    // }
    // if (masterNameValue && subModuleIdValue && masterKeyValue) {
    //   getSubMasterKey(
    //     e.target.value,
    //     subModuleIdValue,
    //     masterKeyValue,
    //     masterNameValue
    //   );
    // }
  };

  // const handleChangeSub = (e) => {
  //   setMasterNameValue("");
  //   setSubModuleIdValue(e.target.value);
  //   if (moduleValue && masterKeyValue) {
  //     getMasterName(moduleValue, e.target.value, masterKeyValue);
  //   }
  //   // if (moduleValue && subModuleIdValue && masterKeyValue) {
  //   //   getSubMasterKey(
  //   //     moduleValue,
  //   //     e.target.value,
  //   //     masterKeyValue,
  //   //     masterNameValue
  //   //   );
  //   // }
  // };
  const handleChangeMasterKey = (e) => {
    setMasterNameValue("");
    setSubMasterKeyValue("");
    setMasterKeyValue(e.target.value);
    if (moduleValue) {
      getMasterName(moduleValue, e.target.value);
    }
    getSubMasterKey(e.target.value);
    // if (moduleValue && subModuleIdValue && masterKeyValue) {
    //   getSubMasterKey(
    //     moduleValue,
    //     subModuleIdValue,
    //     e.target.value,
    //     masterNameValue
    //   );
    // }
  };

  const handleChangeMasterName = (e) => {
    setMasterNameValue(e.target.value);
    if (e.target.value !== "") {
      setNewMasterName("");
    }
  };
  const handleChangeSubMasterKey = (e) => {
    setSubMasterKeyValue(e.target.value);
  };

  //! Dropdown Api calls
  function getModule() {
    getModuleList(siteToken)
      .then((response) => {
        setModuleId(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getSubModule(id) {
    getAllSubModuleList(siteToken, id, true)
      .then((response) => {
        setSubModuleId(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getMasterKey() {
    getMasterKeyList(siteToken, moduleId)
      .then((response) => {
        setMasterKey(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getSubMasterKey(masterKeyId) {
    getSubMasterKeyList(siteToken, masterKeyId)
      .then((response) => {
        setSubMasterKey(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getMasterName(moduleId, masterKeyId) {
    getMasterNameList(siteToken, moduleId, masterKeyId)
      .then((response) => {
        setMasterName(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //! Get method by id
  function getMasterData() {
    var id = atobSecure(encryptedId);
    setIsLoader(true);
    getLinkedMasterListById(siteToken, id)
      .then((response) => {
        setMasterId(response[0].masterId);
        setModuleIdValue(response[0].moduleId);
        getSubModule(response[0].moduleId);
        getMasterKey(response[0].moduleId, response[0].subModuleId);
        getMasterName(response[0].moduleId, response[0].masterKeyId);
        getSubMasterKey(
          response[0].moduleId,
          response[0].subModuleId,
          response[0].masterKeyId,
          response[0].masterName
        );
        //setSubModuleIdValue(response[0].subModuleId);
        setMasterKeyValue(response[0].masterKeyId);
        setMasterNameValue(response[0].masterName);
        setSubMasterKeyValue(response[0].subMasterKeyId);
        setSubMasterName(response[0].subMasterName);
        setStatus(response[0].status);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  var formdata = {
    moduleId: moduleValue,
    //subModuleId: subModuleIdValue,
    masterKeyId: masterKeyValue,
    masterName: masterNameValue,
    newMasterName: newMasterName,
    subMasterKeyId: parseInt(subMasterKeyValue),
    subMasterName: subMasterName,
    status: status,
    createdBy: userDetails.userId,
  };

  var formdataEdit = {
    linkedMasterId: atobSecure(encryptedId),
    moduleId: moduleValue,
    masterId: masterId,
    masterKeyId: masterKeyValue,
    masterName: masterNameValue,
    newMasterName: newMasterName,
    subMasterKeyId: parseInt(subMasterKeyValue),
    subMasterName: subMasterName,
    status: status,
    createdBy: userDetails.userId,
  };
  function addLinkedMaster() {
    setIsLoader(true);
    insertLinkedMaster(formdata, siteToken)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Linked Master Added Successfully.", "success");
          setIsLoader(false);
          reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Data Already Exists.", "warning");
          reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Linked Master.", "error");
          reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }
  function reset() {}

  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }

  function editLinkedMaster() {
    setIsLoader(true);
    UpdateLinkedMaster(formdataEdit, siteToken)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen(
            "Linked Master Data Updated Successfully.",
            "success"
          );
          setIsLoader(false);
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Data Already Exists.", "warning");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Linked Master.", "error");
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (siteToken) {
      getModule();
      getMasterKey();
    }
    if (encryptedId) {
      setPageHeader("Edit Linked Master");
      getMasterData();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>

      <Grid container spacing={2} direction="row" className="upload-block pt-1">
        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Module Name"
            id="module"
            name="Type"
            className=""
            fullWidth
            value={moduleValue}
            helperText={moduleIdHelper}
            onChange={handleChangeModule}
            variant="filled">
            {moduleId.map((item) => (
              <MenuItem key={item.moduleId} value={item.moduleId}>
                {item.moduleName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Master Key"
            id="masterKey"
            name="Type"
            className=""
            fullWidth
            value={masterKeyValue}
            onChange={handleChangeMasterKey}
            variant="filled">
            <MenuItem key={""} value={""}>
              None
            </MenuItem>
            {masterKey.map((item) => (
              <MenuItem key={item.masterKeyId} value={item.masterKeyId}>
                {item.masterKeyName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Master Name"
            id="masterName"
            name="Type"
            className=""
            fullWidth
            value={masterNameValue}
            onChange={handleChangeMasterName}
            variant="filled">
            {!encryptedId ? <MenuItem value={""}>None</MenuItem> : <></>}
            {masterName.map((item) => (
              <MenuItem key={item.masterName} value={item.masterName}>
                {item.masterName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="New Master Name"
            id="newMasterName"
            name="Code"
            className=""
            fullWidth
            variant="filled"
            value={newMasterName}
            helperText={masterNameValue.length > 0 ? "" : masterNameHelper}
            inputProps={{ maxLength: 100 }}
            disabled={masterNameValue.length > 0 ? true : false}
            onChange={handleChangeNewMasterName}
          />
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Sub-Master Key"
            id="subMasterKey"
            name="Type"
            className=""
            fullWidth
            value={subMasterKeyValue}
            onChange={handleChangeSubMasterKey}
            variant="filled">
            {subMasterKey.map((item) => (
              <MenuItem key={item.subMasterKeyId} value={item.subMasterKeyId}>
                {item.subMasterKeyName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Sub-Master Name"
            id="subMasterName"
            name="Code"
            className=""
            fullWidth
            variant="filled"
            value={subMasterName}
            helperText={subMasterNameHelper}
            inputProps={{ maxLength: 100 }}
            onChange={handleChangeSubMaster}
          />
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            select
            label="Status"
            id="status"
            name="Status"
            className=""
            fullWidth
            value={status}
            onChange={handleStatus}
            variant="filled">
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className="upload-block d-flex jc-center mt-1">
        {encryptedId ? (
          <Grid item xs={12} md={2} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={editLinkedMaster}
              disabled={!subMasterName}
              fullWidth>
              Update
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        {!encryptedId ? (
          <Grid item xs={12} md={2} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              fullWidth
              onClick={addLinkedMaster}
              disabled={
                !moduleValue ||
                !masterKeyValue ||
                (masterNameValue && (!subMasterKeyValue || !subMasterName)) ||
                (!masterNameValue && !newMasterName) ||
                (subMasterKeyValue && !subMasterName)
              }>
              Submit
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={2} className="align-center width-100">
          <Link to={"/linkedMasterList"}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { getPaymentList } from "../../Redux/API/api_payment";
import AddIcon from "@material-ui/icons/Add";
import Loader from "../../Components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
    //borderBottom: `2px solid #00BCBC`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
}));
export default function CaseDetails() {
  const classes = useStyles();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const [paymentData, setPaymentData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);

  function GetEditButton(rowData) {
    return (
      <Button href="javascript:void(0)">
        <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
      </Button>
    );
  }

  const columnsForm = [
    { title: "Consultant Name", field: "consultantName" },
    { title: "Nature", field: "natureOfServiceName" },
    { title: "ToolCase No", field: "toolCaseNo" },
    { title: "Consultant PO No", field: "consultantPONumber" },
    {
      title: "Consultant PO Date",
      field: "consultantPODateString",
    },
    {
      title: "Consultant PO Value",
      field: "consultantPOValue",
    },
    {
      title: "Consultant Invoice No",
      field: "consultantInvNo",
    },
    {
      title: "Consultant Invoice Date",
      field: "consultantInvDateString",
    },
    {
      title: "Consultant Invoice Amount",
      field: "consultantInvAmt",
    },
    {
      title: "Ratio",
        field: "ratioTypeName",
    },
    {
      title: "GR Number",
      field: "grNo",
    },
    {
      title: "GR Date",
      field: "grDateString",
    },
    {
      title: "SAP Booking Doc NO",
      field: "sapBookingDocNo",
    },
    {
      title: "Payment Release Date",
      field: "paymentReleaseDateString",
    },
    {
      title: "Remaining PO Value",
      field: "totalPaymentMadeToConsultantTillDate",
    },
    {
      title: "PO Creator",
      field: "poCreaterName",
    },
    {
      title: "Remarks",
      field: "remarks",
    },
  ];

  function NavigatePaymentPage() {
    window.location.href = "/addPayment";
  }
  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }

    function clickEditPaymentDetails(rowData) {
    let id = rowData.paymentId;
    let idEncrypted = btoaSecure(id);
    window.location.href = "/addPayment?id=" + idEncrypted;
  }
  function getPayment() {
    setIsLoader(true);
    getPaymentList(token, 0)
        .then((response) => {
        setPaymentData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getPayment();
  }, [token]);

  return (
    <div className="p-1">
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} className="d-flex fd-column">
          <h3 className={classes.sectionHeader}>Payment Details</h3>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={12}>
          <MaterialTable
            title=""
            columns={columnsForm}
            data={paymentData}
            icons={tableIconsInputScreen}
            options={{
              exportButton: {
                csv: true,
                pdf: false,
              },
              search: true,
              pageSize: 10,
              exportAllData: true,
              emptyRowsWhenPaging: false,
              exportFileName: "PaymentList",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
            actions={[
              {
                icon: AddIcon,
                isFreeAction: true,
                tooltip: "Add Payment",
                onClick: () => {
                  NavigatePaymentPage();
                },
              },
              {
                icon: GetEditButton,
                tooltip: "Edit Payment",
                onClick: (event, rowData) => clickEditPaymentDetails(rowData),
              },
            ]}
          />
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}

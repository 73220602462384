import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//import { baseAddressURL } from "../../../Utilities/Utility";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getCompanyList } from "../../../Redux/API/api_userManagement";
import {
  addBUMaster,
  editBUMaster,
  getBUMasterList,
  getDivisionMasterList,
} from "./../../../Redux/API/api_master";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddBU() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add Business Unit");
  const [data, setData] = useState([]);
  const [buId, setBuId] = useState("");
  const [divisionCode, setDivisionCode] = useState([]);
  const [divisionCodeValue, setDivisionCodeValue] = useState("");
  const [buName, setBuName] = useState("");
  const [buNameHelper, setBuNameHelper] = useState("");
  const [buCode, setBuCode] = useState("");
  const [buCodeHelper, setBuCodeHelper] = useState("");
  const [statusValue, setStatusValue] = useState(true);
  let [isLoader, setIsLoader] = useState(false);

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/buList";
    }
  };

  //! Dropdown functions
  const handleChangeDivisionCode = (e) => {
    setDivisionCodeValue(e.target.value);
  };
  const handleChangebuCode = (e) => {
    setBuCode(e.target.value);
    let valid = /^[a-zA-Z0-9 _-]*$/.test(e.target.value);
    if (!valid) {
      setBuCodeHelper("Please enter valid values.");
    } else if (e.target.value.length < 1) {
      setBuCodeHelper("Minimum 2 characters required");
    } else {
      setBuCodeHelper("");
    }
  };
  const handleChangeBuName = (e) => {
    setBuName(e.target.value);
    if (e.target.value.length === 0) {
      setBuNameHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setBuNameHelper("Length cannot excced more than 200.");
    } else {
      setBuNameHelper("");
    }
  };
  const handleChangeStatus = (e) => {
    setStatusValue(e.target.value);
  };

  //! Dropdown Get Methods

  function getDivision() {
    getDivisionMasterList(siteToken)
      .then((response) => {
        setDivisionCode(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //! Post Method
  var formdata = {
    buCode: buCode,
    buName: buName,
    divisionId: divisionCodeValue,
    status: statusValue,
    createdBy: userDetails.userId,
  };

  var formdataEdit = {
    buId: buId,
    buCode: buCode,
    buName: buName,
    divisionId: divisionCodeValue,
    status: statusValue,
    modifiedBy: userDetails.userId,
  };

  function addBUMasterData() {
    setIsLoader(true);
    addBUMaster(formdata, siteToken)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Business Unit Added Successfully.", "success");
          setIsLoader(false);
          //reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Business Unit Already Exists.", "warning");
          //reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Business Unit.", "error");
          //reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }
  function editBUMasterData() {
    setIsLoader(true);
    editBUMaster(formdataEdit, siteToken)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Business Unit Edited Successfully.", "success");
          setIsLoader(false);
          //reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Business Unit Already Exists.", "warning");
          //reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Editing Business Unit.", "error");
          //reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }
  //! Edit - Get method
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");

  function getBUList() {
    setIsLoader(true);
    getBUMasterList(siteToken, atobSecure(encryptedId))
      .then((response) => {
        setBuCode(response[0].buCode);
        setBuName(response[0].buName);
        setDivisionCodeValue(response[0].divisionId);
        setStatusValue(response[0].status);
        setBuId(response[0].buId);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (siteToken) {
      getDivision();
    }
    if (encryptedId) {
      setPageHeader("Edit Business Unit");
      getBUList();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>
      <div className="box-shadow">
        <Grid
          container
          spacing={2}
          direction="row"
          className="upload-block pt-1">
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Business Unit Code"
              id="businessUnitCode"
              name="businessUnitCode"
              className=""
              value={buCode}
              onChange={handleChangebuCode}
              helperText={buCodeHelper}
              fullWidth
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={4} className="">
            <TextField
              label="Business Unit Name"
              id="businessUnitName"
              name="businessUnitName"
              className=""
              fullWidth
              variant="filled"
              value={buName}
              helperText={buNameHelper}
              inputProps={{ maxLength: 100 }}
              onChange={handleChangeBuName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Division Code"
              id="masterKey"
              name="Master Key"
              className=""
              select
              value={divisionCodeValue}
              onChange={handleChangeDivisionCode}
              fullWidth
              variant="filled">
              {divisionCode.map((item) => (
                <MenuItem key={item.divisionId} value={item.divisionId}>
                  {item.divisionName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              select
              label="Status"
              id="status"
              name="Status"
              className=""
              fullWidth
              value={statusValue}
              onChange={handleChangeStatus}
              variant="filled">
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="upload-block d-flex jc-center pb-2">
          {encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={editBUMasterData}
                disabled={!buName || !buCode}
                fullWidth>
                Update
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {!encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={addBUMasterData}
                disabled={!buName || !buCode || !divisionCodeValue}
                fullWidth>
                Submit
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={1} className="align-center width-100">
            <Link to={"/buList"}>
              <Button
                type="submit"
                className="pt-button--secondary"
                id="SupplierLoginBtn"
                fullWidth>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
        {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      </div>

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
